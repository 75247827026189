import React, { useState, useContext } from "react";

import { Box, Link } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InfoIcon from "@mui/icons-material/Info";
import FeedIcon from "@mui/icons-material/Feed";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import CallIcon from "@mui/icons-material/Call";

import { MouseContext } from "../../context/mouse-context";

export default function TemporaryDrawer({ className }) {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const iconsRender = (index) => {
    if (index === 0) {
      return <InfoIcon />;
    } else if (index === 2) {
      return <FeedIcon />;
    } else if (index === 3) {
      return <LiveHelpIcon />;
    } else if (index === 4) {
      return <CallIcon />;
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["About", "Leja"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{iconsRender(index)}</ListItemIcon>
            <ListItemText>
              {text === "About" ? (
                <Link
                  href="/about"
                  className="links"
                  onMouseEnter={() => cursorChangeHandler("hovered")}
                  onMouseLeave={() => cursorChangeHandler("")}
                  data-aos="fade-left"
                  data-aos-duration="1600"
                >
                  ABOUT US
                </Link>
              ) : (
                <Link
                  href="/"
                  className="links"
                  onMouseEnter={() => cursorChangeHandler("hovered")}
                  onMouseLeave={() => cursorChangeHandler("")}
                  data-aos="fade-left"
                  data-aos-duration="1600"
                >
                  LEJA
                </Link>
              )}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className={className}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {<MenuIcon className="menu-icon" />}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
