import React from "react";
import { Container, Stack, Grid, Typography, Box, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Navbar } from "../../components/navigation/navbar";
import Footer from "../../components/footer/footer";

const root = {
  // charges-interest
  display: "flex",
  flexDirection: "column",
  height: "100%",
};
const container = {
  // maxWidth: '780px',
  padding: "80px 24px",
  display: "flex",
  flexDirection: "column",
  minHeight: "98vh",
};
const paragraph = {
  // fontWeight: "390",
  // fontSize: "16px",
  // fontFamily: "Rubik",
  // color: "#000000",

  marginTop: "1rem",
  marginLeft: "1.5rem",
  marginBottom: "0.7rem",
};
const cover = {
  height: "450px",
  width: "100%",
  position: "relative",
  margin: "0 auto",
  //   backgroundImage: `url(${banner})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const heading = {
  color: "black",
  fontSize: "30px",
  paddingTop: "12px",
  fontWeight: "bold",
};
const mainHeading = {
  color: "black",
  fontSize: "20px",
  paddingTop: "20px",
  textAlign: "center",
};
const subHeading = {
  color: "black",
  fontSize: "25px",
  paddingTop: "10px",
  fontWeight: "bold",
};

const Ulist = {
  listStyleType: "disc",
  display: "list-item",
  listStylePosition: "outside",
};
const contactList = {
  marginTop: 1,
  padding: 3,
  textAlign: "left",
  fontWeight: "bold",
  width: "40%",
};

const table = {
  minWidth: 650,
};
const headerCell = {
  fontWeight: "bold",
  fontSize: "18px",
  borderBottom: "solid #000000 1px",
  backgroundColor: "#ececec",
};
const tableCell = {
  borderRight: "solid #000000 1px",
};
const title = {
  textAlign: "center",
  fontWeight: "800",
  color: "#000000",
  fontSize: "30px",
  fontFamily: "Rubik",
  marginTop: "2rem",
};
const date = {
  textAlign: "left",
  fontWeight: "bold",
};

const OkoleaTermsAndConditions = (props) => {
  return (
    <Container>
      <Navbar />
      <Box
        variant="h4"
        gutterBottom
        sx={{
          marginTop: "6rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box variant="h2" gutterBottom sx={title}>
          FORTUNE LEJA ACCOUNTS TERMS AND CONDITIONS
        </Box>
        <Box sx={date}>Last updated April 27, 2023</Box>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      ></Stack>
      <Box sx={container}>
        <Grid container spacing={2}>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              1. The Agreement
            </Box>
            <Box>
              <p>
                This Agreement sets out the complete Terms and Conditions
                (hereinafter called "these Terms and Conditions") which shall be
                applicable to the For-Leja (as hereinafter defined) opened by
                you (as hereinafter defined) with the Fortune Sacco (as
                hereinafter defined).
              </p>
              <p>
                These Terms and Conditions and any amendments or variations
                thereto take effect on their date of publication.
              </p>
              <p>
                The Member accepts and acknowledges that this is a legal and
                binding agreement between the Member and the Sacco once accepted
                by both parties.
              </p>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              2. Definitions in these Conditions
            </Box>
            <p>
              {" "}
              In these Terms and Conditions, the following words and expressions
              (save where the context requires otherwise) bear the following
              meanings;
            </p>
            <Box style={{ width: "100%", textAlign: "justify" }}>
              <ol sx={Ulist}>
                <li>
                  <span>
                    {" "}
                    <strong> “Fortune Sacco Account”</strong>
                  </span>{" "}
                  means a Sacco account opened by a member with the Sacco and
                  which is opened and operated in accordance with the terms and
                  conditions herein contained;
                </li>
                <li>
                  <span>
                    {" "}
                    <strong> "Sacco"</strong>
                  </span>{" "}
                  means Fortune Sacco Ltd incorporated in Kenya as a limited
                  liability company under the Companies Act and duly licensed as
                  a Sacco under the Co-operative Societies Act (Cap 490 of the
                  Laws of Kenya) and includes subsidiaries of the Sacco as may
                  from time to time be specified by the Sacco to you;
                </li>
                <li>
                  <span>
                    {" "}
                    <strong> “Credit Reference Bureau”</strong>
                  </span>{" "}
                  means a credit reference bureau duly licensed under the
                  Banking Act pursuant to the Banking (Credit Reference Bureau)
                  Regulations, 2008 to inter alia, collect and facilitate the
                  sharing of customer credit information;
                </li>
                <li>
                  <span>
                    <strong> “Member/Customer”</strong>{" "}
                  </span>{" "}
                  means the person in whose details the Fortune account is
                  existing;
                </li>
                <li>
                  <span>
                    <strong>"IPRS"</strong>{" "}
                  </span>{" "}
                  means the Integrated Population Registration System set up and
                  maintained by the Government of Kenya under the Ministry of
                  State for Immigration and Registration of Persons;
                </li>
                <li>
                  <span>
                    <strong>"Loans Menu"</strong>
                  </span>{" "}
                  or means the loans menu item on the Leja mobile application;
                </li>
                <li>
                  Asilimia, Fortune Sacco Limited and the Customer shall
                  together be referred to as the <strong>“Parties”</strong> and
                  where the context requires individually as a “Party”.
                </li>
                <li>
                  The masculine gender includes the feminine and vice versa.{" "}
                </li>
                <li>
                  <strong>“We,” “our,”</strong> and <strong> “us,”</strong>{" "}
                  means Asilimia and Fortune Sacco Limited and includes our
                  successors and assigns.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              3. ACCEPTANCE OF THE TERMS AND CONDITIONS
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  Before consenting to open the Fortune Sacco Account via the
                  Leja system you should carefully read and understand these
                  Terms and Conditions which will govern the use and operation
                  of the For-Leja Account.
                </li>
                <li>
                  If you do not agree to these Terms and Conditions, kindly
                  click “Deny” on the loans Menu.
                </li>
                <li>
                  You will be deemed to have read, understood and agreed these
                  conditions;
                  <li>
                    Upon clicking “Accept” option on the loans Menu requesting
                    you to confirm that you have read, understood and accepted
                    to abide with these Terms and Conditions; and/or
                  </li>
                  <li>
                    By using or continuing to use and operate the For-Leja
                    Account.
                  </li>
                </li>
                <li>
                  By applying to open the For-Leja Account with the Sacco, two
                  accounts: shares capital account and savings Account will be
                  created under your details. You therefore agree to comply with
                  and be bound by these Terms and Conditions for the time being
                  and from time to time in force governing the operation of
                  For-Leja Account and you confirm that these Terms and
                  Conditions herein are without prejudice to any right that the
                  Sacco may have with respect to the Fortune Sacco Account in
                  law or otherwise.
                </li>
                <li>
                  These Terms and Conditions as well as the Privacy Terms may be
                  varied or ammended by the Sacco from time to time. Continued
                  use of your For-Leja Account after the notice is issued
                  constitutes your agreement to be bound by the terms of any
                  such variation or amendment.
                </li>
                <li>
                  You acknowledge and accept that the Sacco offers the For-Leja
                  Account only electronically and you agree to do business with
                  the Sacco and to operate the For-Leja Account only by
                  electronic means via the For-Leja Menu on the Leja System and
                  any other transaction channel provided by the Sacco. This
                  would mean that customers can access and receive assistance on
                  issues pertaining to this kind of an account at either of the
                  Fortune Sacco branches or use other Sacco channels to operate
                  the account. Any query and complaint you may have relating to
                  the Services shall be addressed to the Sacco through the Leja
                  Customer Care Centre. You further acknowledge and accept that
                  the Leja Customer Care Centre is not a branch of the Sacco but
                  a partner to the Sacco for purposes of conducting For-Leja
                  business or transactions and that it will as such.
                </li>
                <li>
                  You further confirm that you have considered the charges and
                  interest levied by the Company as specified and fully
                  understands that failure to settle the Loan on the due date
                  shall result to a penalty fee of 0.5% each day of the first
                  one month of defaulting and 1% each day thereafter, up to a
                  maximum of 100% of the loan requested and thereafter, CRB
                  listing if not repaid by the sixth month of it falling due.
                </li>
              </ol>
            </Box>
            <Box sx={heading}>4. ACCOUNT OPENING</Box>
            <Box sx={paragraph} m={1}>
              <ol sx={Ulist}>
                <li>
                  In order to open a For-Leja Account with the Sacco, you must
                  be at least 18 years old and a registered and active Leja
                  Subscriber. The Bank reserves the right to verify with
                  Safaricom the authenticity and status of your Leja Account.
                </li>
                <li>
                  This partnership enables Leja customers to open a Fortune
                  Sacco account to be able access the loan service. Hence,the
                  Sacco may request Leja for your personal information relating
                  to your usage of Leja.
                </li>
                <li>
                  Before an account is opened, you will be required to undergo
                  the Leja KYC process and thereafter Leja will submit your
                  details to IPRS for verification.
                </li>
                <li>
                  The Sacco may request Leja for your personal information held
                  by Leja pursuant to the agreement between you and Leja for the
                  provision of Leja products and services including your phone
                  number, name, date of birth, ID or Passport Number, industry,
                  passport photo, next of kin details,location addresses and
                  such other information that will enable the Sacco to identify
                  you and comply with the regulatory{" "}
                  <strong>“Know Your Customer”</strong>
                  requirements.
                </li>
                <li>
                  The Sacco may also seek your consent to approve the disclosure
                  of the Personal Information and the Leja Information by Leja
                  to the Sacco and to the aforesaid use of the Personal
                  Information and the Leja Information by the Sacco to inform
                  loan appraisal and disbursement.
                </li>
                <li>
                  We may employ a range of technologies and internal policy
                  framework to protect the information and data maintained on
                  our systems from loss, unauthorized access, disclosure,
                  alteration, or destruction and has at all times taken and
                  takes all steps reasonably necessary to ensure the
                  confidentiality and integrity of such Personal Information and
                  information and the way it is processed. These policies and
                  standards are periodically updated to keep them up to date
                  with regulations and market developments.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              5. CASH LOAN APPLICATION
            </Box>
            <Box m={1}>
              <ol sx={Ulist}>
                <li>
                  As a Fortune Sacco account holder, you may, subject to these
                  Terms and Conditions, apply for a loan from the Sacco using
                  the Loans menu on the Leja app in your equipment.
                </li>
                <li>
                  When you apply for a loan from the Sacco, your loan limit
                  shall be appraised according to the applicable loan appraisal
                  processes developed by Leja in partnership with Fortune Sacco.
                  Leja and Fortune Sacco reserve the right at its sole
                  discretion and without assigning any reason to approve or
                  decline your request to apply for a loan.
                </li>
                <li>
                  Subject to approval of your application for a loan, the Sacco
                  shall disburse to you a loan amount within your approved loan
                  limit from a minimum amount of One Thousand Kenyan Shillings
                  (Kshs. 1000/=) to a maximum amount of Twenty Thousand Kenyan
                  Shillings (Kshs. 20,000/=) or such other minimum or maximum
                  amount as the Sacco may from time to time in its sole
                  discretion determine.
                </li>
                <li>
                  Loan disbursement shall be made to the Mpesa registered number
                  tied to your Fortune Sacco account which is subject to
                  applicable Transaction fees and future changes that the Sacco
                  in partnership with Leja may implement.
                </li>
              </ol>
            </Box>
            <Typography m={1} sx={subHeading}>
              6 LOAN CHARGES AND FEES
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCell}>#</TableCell>
                    <TableCell sx={headerCell} align="left">
                      Loan amount
                    </TableCell>
                    <TableCell sx={headerCell} align="left">
                      Interest rate
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="4.1.1">
                    <TableCell align="right" sx={tableCell}>
                      1
                    </TableCell>
                    <TableCell component="th" scope="row" sx={tableCell}>
                      1000-20000
                    </TableCell>
                    <TableCell align="left">15%</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              7. LOAN REPAYMENT
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  The Loan is repayable on the Due Date through the Leja
                  Application.
                </li>
                <li>
                  Should the Loan not be paid on the Due Date, the Company
                  shall, though not obligated, demand from the Customer all
                  monies, which may then or thereafter be due and owing under
                  these Loan Conditions, including but not limited to:
                  <ul>
                    <li>
                      All Loan appraisal fees due, interest due on Loan and
                      principal Loan due.
                    </li>
                    <li>
                      {" "}
                      All legal and other costs, charges and expenses which the
                      Company may pay or incur in connection with these Loan
                      Conditions or the recovery of any monies owing here under;
                    </li>
                    <li>
                      A fee to compensate the Company for a reasonable estimate
                      of any loss incurred by the Bank as a result of default to
                      the full extent permitted by law; and
                    </li>
                    <li>
                      All monies due and computed from the due date until the
                      settlement in full.
                    </li>
                  </ul>
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              8. INDEMNITY AND RECOVERY OF COSTS
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  The Customer undertakes to indemnify and keep Asilimia and
                  Fortune Sacco indemnified at all times against all actions,
                  claims, demands, liabilities, losses, damages, costs, charges
                  and expenses of whatever nature inclusive of any legal costs
                  and disbursements incurred by the either Asilimia or Fortune
                  Sacco in obtaining payment of any monies due and owing to the
                  forementioned Parties from the Customer. The indemnity shall
                  remain valid, subsisting and binding upon the Customer
                  notwithstanding withdrawal & termination of the contract.
                </li>
                <li>
                  Any legal costs and disbursements incurred by either Asilimia
                  or Fortune Sacco Ltd as against the Customer shall be deemed
                  to include every sum which would be allowed to the Advocates
                  of the forementioned parties in taxation between the Advocate
                  and clients to the intent that the Customer shall afford to
                  the Asilimia and Fortune Sacco a complete entitlement and
                  unqualified indemnity in respect thereof.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              9. VARIATION
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  We reserves the right to vary, amend or replace all or any of
                  these Conditions at any time without prior notice. We shall
                  notify the Customer of any changes made to these Conditions as
                  soon as is practicable and by the most expedient means as
                  determined by the Company or Fortune Sacco provided that
                  failure to make such notification shall not invalidate the
                  changes.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              10. BREACH OF CONDITIONS
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  In the event of any breach by the Customer of any of these
                  Conditions the Company may in circumstances where the Customer
                  fails to comply or procure compliance with the terms of a
                  notice served by the Company on the Customer, require
                  immediate repayment in full of the outstanding balance on the
                  Active Customer Account.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              11. LOAN SUSPENSION.
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  The Company may at any time and without notice cancel or
                  suspend the right to utilize Loan entirely or entirely
                  withdraw the Loan as a product without affecting the Account
                  User’s obligations under these Loan Conditions.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              12.TERMINATION
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  Either Party may terminate their obligations under these
                  Conditions at any time on written notice to the other Party.
                  On termination by the Customer the termination notice should
                  be accompanied by the repayment of the full Loan outstanding
                  at the time of receipt.
                </li>
                <li>
                  Termination shall only be effective upon the discharge of all
                  the outstanding liabilities under these Loan Conditions.
                </li>
                <li>
                  The Company may cancel any Loan on written notice from
                  Customer accompanied by the payment of all sum outstanding
                  Loan balance.
                </li>
                <li>
                  We may at any time and without giving reasons or notice
                  terminate these Conditions and upon such termination the
                  Customer must repay the full outstanding Loan balance within
                  the contracted period.
                </li>
                <li>
                  Termination by either the Customer or us shall not affect the
                  Customer’s obligations to meet any liabilities incurred prior
                  to such termination. This condition will continue until the
                  Customer has repaid all amounts outstanding at the time of
                  termination.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              13. DISCLOSURE OF INFORMATION
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  The Customer agrees that the Company may disclose details
                  relating to the Loan to any third Party (including credit
                  agencies and our partners) if in the Company’s opinion such
                  disclosure is necessary for the purpose of evaluating any
                  application made to the Company or such third Party or other
                  purpose as the Company shall deem appropriate.
                </li>
                <li>
                  The Customer agrees that the Company may disclose details
                  relating to the Loan including details of default in servicing
                  the Active User Account to any third Party (including credit
                  agencies and partners) for the purpose of evaluating the
                  Account holder’s credit worthiness or for any other lawful
                  purpose.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              14. GOVERNING LAW AND JURISDICTION
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  These Loan Conditions are governed in all respects by the Laws
                  of Kenya.
                </li>
                <li>
                  A dispute, difference or question which may arise between the
                  Parties Limited to the interpretation of right and liabilities
                  of either Party shall be resolved through good faith
                  negotiation between the parties and failing such to be
                  referred to an arbitrator under the rules of the Arbitration
                  (Amendment) Act 2009 of Kenya or any statutory modification or
                  re-enactment for the time being in force, such arbitrator to
                  be appointed by agreement of both parties and in the absence
                  of agreement within fourteen (14) days of notification of the
                  dispute by either Party to the other then on the application
                  of any one party to the Chairman of the Kenyan Chapter of the
                  Chartered Institute of Arbitrators and the decision of such
                  arbitrator shall be final and binding on the Parties hereto.
                </li>
                <li>
                  Neither Party shall be entitled to commence or maintain an
                  action in a Court of law upon any matter in dispute until such
                  matter shall have been submitted and determined as herein
                  before provided and then only for the enforcement of the
                  arbitration award or such matter as is permitted by law.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              15. NON-ASSIGNMENT
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  The Customer may not assign or otherwise dispose of any of the
                  Customer's rights and obligations under this Agreement. We may
                  transfer or assign its rights and obligations under these
                  Conditions and such transfer or assignment shall be effective
                  upon notification of the same to the Customer.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              16. NOTICES AND CHANGE OF ADDRESS
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  All notices to the Account User made under these Conditions
                  shall be sent by short message system(SMS) to the mobile
                  telephone number availed by the Account User or by prepaid
                  post to the last known postal address of the Account User
                  whichever will be deemed to be expedient by Asilimia and
                  Fortune Sacco Limited.
                </li>
                <li>
                  The Customer shall notify the us in writing of any change of
                  address. Until such notice is received the Account User’s
                  address shall be that stated in Mobile App sign in details
                  provided.
                </li>
              </ol>
            </Box>
            <Box fontWeight="fontWeightBold" m={1} sx={heading}>
              17. CREDIT REFERENCE BUREAUS
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
              <ol sx={Ulist}>
                <li>
                  The Customer expressly consents and allows us to forward
                  personal data and full file credit information to licensed
                  credit reference bureaus in accordance with the Banking
                  (Credit Reference Bureaus) Regulations, 2008.
                </li>
              </ol>
            </Box>
          </Typography>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
};

export default OkoleaTermsAndConditions;
