import React from "react";
import { Container, Stack, Box } from "@mui/material";
import { Navbar } from "../../components/navigation/navbar";
import Footer from "../../components/footer/footer";
// import banner from "../../assets/images/others/banner.png";
import consentImg from "../../assets/consent.jpg";

const root = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
};
const container = {
  //  maxWidth: '780px',
  padding: "80px 24px",
  display: "flex",
  flexDirection: "column",
  minHeight: "98vh",
};
const title = {
  textAlign: "center",
  fontWeight: "800",
  color: "#000000",
  fontSize: "25px",
  fontFamily: "Rubik",
  marginTop: "2rem",
};
const cover = {
  height: "450px",
  width: "100%",
  position: "relative",
  margin: "0 auto",
  // backgroundImage: `url(${banner})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const heading = {
  color: "black",
  fontSize: "30px",
  paddingTop: "20px",
  fontWeight: "bold",
};
const mainHeading = {
  color: "black",
  fontSize: "20px",
  paddingTop: "20px",
  textAlign: "center",
};
const subHeading = {
  color: "black",
  fontSize: "16px",
  paddingTop: "20px",
  fontWeight: "bold",
};
const image = {
  width: "80%",
  height: "50%",
};

const Ulist = {
  listStyleType: "disc",
};
const contactList = {
  marginTop: 1,
  padding: 3,
  textAlign: "left",
  fontWeight: "700",
  width: "40%",
};

const date = {
  fontWeight: "400",
  fontSize: "14px",
  fontFamily: "Rubik",
  color: "#000000",
};

const OkoleaPrivacyPolicy = (props) => {
  return (
    <Container>
      <Navbar />
      <Box
        variant="h4"
        gutterBottom
        sx={{
          marginTop: "5rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box variant="h2" gutterBottom sx={title}>
          PRIVACY POLICY -CHANGE AGENTS APPLICATION
        </Box>
        <Box sx={date}>Last updated July 5th, 2022</Box>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      ></Stack>

      <Box sx={heading}>Introduction</Box>
      <Box fontWeight="fontWeightLight" m={1}>
        <span>
          <strong>ASILIMIA</strong>
        </span>{" "}
        (“us”, “we”, or “our”) operates the CHANGE AGENTS mobile application
        (the “Service”).
        <p>
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
        </p>
        <p>
          {" "}
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which is accessible herein unless otherwise
        defined in this Privacy Policy.
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Information we collect about you and your device.
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        For a better experience, while using our Service, we may automatically
        collect the following information:
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        <ol>
          <li>
            <strong>Personal Data</strong>
          </li>
          <p>
            Demographic and other personally identifiable information (such as
            your name, email address, Phone Number, Email, Sex, Physical
            Address.) that you voluntarily give to us when choosing to
            participate in various activities related to the Application, such
            as chat, posting messages in comment sections or in our forums,
            liking posts, sending feedback, and responding to surveys.
          </p>
          <p>
            If you choose to share data about yourself via your profile, online
            chat, or other interactive areas of the Application, please be
            advised that all data you disclose in these areas is public and your
            data will be accessible to anyone who accesses the Application.
          </p>
          <li>
            <strong>Device Information</strong>
          </li>
          <p>
            Information stored on your Device, including M-pesa Transaction SMS
            logs.
          </p>
          <p>
            You will be required to grant access to your contact list SHOULD you
            choose to select a contact from your contact list within the
            application while doing a transaction.
          </p>
          <p>
            Device information, including the type of mobile device you use,
            unique device identifiers (for example, your Device’s IMEI or serial
            number), information about the SIM card used by the Device, mobile
            network information, your Device’s operating system and your
            Device’s location.
          </p>
          <p>GPS Location to determine location of your last loan request.</p>
        </ol>
      </Box>
      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Information we receive from other sources:
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        Due to the nature of the Services, which we provide, we are required to
        work with a number of third parties (including credit reference agencies
        and mobile network providers) and we may receive information about you
        from them.
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Why we collect data
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        We collect certain data to be able to provide the Service to you. We
        also collect data for use in verifying your identity and creating
        credit-scoring models to determine what Loans can be offered to you. We
        also use this data for purposes of collections and credit reporting.
        <p>
          <strong>Change Agents</strong> uses the collected data for various
          purposes:
        </p>
        <li>To provide and maintain the Service.</li>
        <li>To notify you about changes to our Service.</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so.
        </li>
        <li>To provide customer care and support.</li>
        <li>
          To provide analysis or valuable information so that we can improve the
          Service.
        </li>
        <li>To monitor the usage of the Service</li>
        <li>To detect, prevent and address technical issues.</li>
      </Box>
      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Where we store your personal data
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        <p>
          The data that we collect from you may be transferred to, and stored
          at, a destination outside your country of origin or residence (as
          applicable) or other governmental jurisdiction where the data
          protection laws may differ than those from your jurisdiction.
        </p>
        <p>
          If you are located outside KENYA and choose to provide information to
          us, please note that we transfer the data, including Personal Data, to
          KENYA and process it there.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>
          ASILIMIA GROUP will take all steps reasonably necessary to ensure that
          your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Consent
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        You are required to give consent immediately you install Change Agents
        app and before using the service on the Permission page shown below.
        <img sx={image} alt="Consent" src={consentImg} />
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Log Data
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        We want to inform you that whenever you use our Service, in a case of an
        error in the app we collect data and information (through third party
        products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the app when
        utilizing our Service, the time and date of your use of the Service, and
        other statistics.
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Disclosure of Data
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        <p>
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <li>
          Credit bureaus, in requesting credit histories or reporting loan
          defaults;
        </li>
        <li>Collections agencies, in seeking to collect overdue Loans;</li>
        <li>
          Government bodies and law enforcement agencies, to comply with the
          law;
        </li>
        <li>
          Professional advisers, to enforce or defend our legal rights; or
        </li>
        <li>
          With a purchaser or seller in connection with a corporate event such
          as a merger, business acquisition or insolvency situation.
        </li>
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Legal Requirements;
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        CHANGE AGENTS may disclose your Personal Data in the good faith belief
        that such action is necessary to:
        <li>To comply with a legal obligation</li>
        <li>To protect and defend the rights or property of ASILIMIA GROUP</li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          To protect the personal safety of users of the Service or the public
        </li>
        <li>To protect against legal liability</li>
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Security
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        Personal Information will be protected by security safeguards that are
        appropriate to the sensitivity level of the information will protect
        personal Information. We take all reasonable precautions to protect your
        Personal Information from any loss or unauthorized use, access or
        disclosure. Except for the aforementioned, we shall not share the
        provided data with any other third parties.
        <p>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Service Providers
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        We may employ third party companies and individuals to facilitate our
        Service (“Service Providers”), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used.
        <p>
          {" "}
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Changes to This Privacy Policy
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.
      </Box>
      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        Children’s Privacy
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        Our Service does not address anyone under the age of 18 (“Children”). We
        do not knowingly collect personally identifiable information from anyone
        under the age of 18. If you are a parent or guardian and you are aware
        that your Child(ren) has/have provided us with Personal Data, please
        contact us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </Box>

      <Box fontWeight="fontWeightBold" m={1} sx={heading}>
        CONTACT US
      </Box>
      <Box fontWeight="fontWeightLight" m={1}>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us:
        <Box elevation={5} sx={contactList}>
          Asilimia Group
          <br />
          <li>
            By email:{" "}
            <a href="mailto: info@asilimia.co.ke"> info@asilimia.co.ke </a>
          </li>
          <li>
            By phone number: <a href="tel: +254111052280"> +254 111 052280 </a>
          </li>
        </Box>
      </Box>

      <Footer />
    </Container>
  );
};

export default OkoleaPrivacyPolicy;
