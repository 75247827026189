import React from "react";
import { Navbar } from "../../components/navigation/navbar";
import Footer from "../../components/footer/footer";
import { Container, Stack, Box } from "@mui/material";
import bookKeeping from "../../assets/book-keeping.webp";

const title = {
  textAlign: "center",
  fontWeight: "800",
  color: "#000000",
  fontSize: "25px",
  fontFamily: "Rubik",
  marginTop: "2rem",
};

const heading = {
  fontWeight: "700",
  fontSize: "23px",
  fontFamily: "Rubik",
  color: "#009966",
  textAlign: "left",
};
const paragraph = {
  fontWeight: "390",
  fontSize: "16px",
  fontFamily: "Rubik",
  color: "#000000",
  marginTop: "1rem",
  marginLeft: "1.5rem",
  marginBottom: "0.7rem",
};
const date = {
  fontWeight: "400",
  fontSize: "14px",
  fontFamily: "Rubik",
  color: "#000000",
};
const TermsAndConditions = () => {
  return (
    <Container>
      <Navbar />
      <Box
        variant="h4"
        gutterBottom
        sx={{
          marginTop: "5rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box variant="h2" gutterBottom sx={title}>
          Leja Terms and Condition
        </Box>
        <Box sx={date}>Last updated May 25th, 2022</Box>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      ></Stack>
      <Box m={1} sx={heading}>
        1. Introduction
      </Box>
      <Box sx={paragraph}>
        <span>
          <strong>ASILIMIA</strong>
        </span>{" "}
        owns <strong>Leja</strong>, a digital ledger application that allows
        business owners to manage their transactions in real-time. With the Leja
        application, Asilimia users will be able to create simple cash-based
        accounting records as well as keep a ledger of outstanding debits and
        credits.
        <p>
          These terms and conditions outline the rules and regulations for the
          use of Leja Business App available at{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.leja"
            target="_blank"
            rel="noreferrer"
          >
            Google Playstore
          </a>{" "}
          and other mobile products affiliated to Asilimia Group.
        </p>
        Also, the terms of service herein address our{" "}
        <a href="/privacy-policy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>{" "}
        and any internal guidelines, supplementary terms, policies, or
        disclaimers made available or issued by us from time to time.
        <p>
          A user must accept and acknowledge that this is a legal and binding
          agreement between the user and the company once accepted by both
          parties.
        </p>
      </Box>
      <Box sx={heading}>2. Definition of Terms</Box>
      <Box sx={paragraph}>
        <p>
          2.1 <strong> “The Company”, “We,” “our,” </strong> and{" "}
          <strong>"us"</strong>
          means the company and includes its successors and assignees;
        </p>
        <p>
          2.2 <strong>"You"</strong>or<strong>"Your"</strong>means the user and
          includes your personal representatives and heirs;
        </p>
        <p>
          2.3 <strong>"User"</strong> or <strong>"Client"</strong> refers to
          you, the person who consumes our services.
        </p>
        <p>
          2.4 <strong>"Equipment"</strong> includes your mobile phone handset,
          SIM Card and/or other equipment which when used together enables you
          to access the Network;
        </p>
        <p>
          2.5 <strong>"Network"</strong> means the mobile cellular network
          operated by various mobile telecommunications networks;
        </p>
        <p>
          2.6 <strong>"Request"</strong> means an instruction received by the
          Asilimia from you or purportedly from you through the Network and the
          System and upon which the Sacco is authorized to act;
        </p>
        <p>
          2.7 <strong>"System"</strong> means the company’s electronic and
          communications software enabling the user to communicate with the
          Company for purposes of the Services. The System and the Services will
          for the purpose of this Agreement be accessed through the Mobile Money
          System;
        </p>
        <p m={1}>
          2.8 <strong>"Leja"</strong> is a cash management and bookkeeping app
          for all kinds of business.
        </p>
        <p m={1}>
          2.8 <strong>"Services"</strong> means any product offered by Leja
          through the mobile application or USSD short code.
        </p>
        <p m={1}>
          Any use of the above terminology or other words in the singular,
          plural, capitalization and/or he/she or they, are taken as
          interchangeable and therefore as referring to the same.
        </p>
      </Box>
      <Box sx={heading}>3. Acceptance of the Terms and Conditions</Box>
      <Box sx={paragraph}>
        <p m={1}>
          Before operating the Leja Mobile Application, you should carefully
          read, understand and accept these Terms and Conditions which will
          govern the operations and use of Leja application.
        </p>
        <p
          m={1}
          sx={{
            fontWeight: "bold",
          }}
        >
          If you do not agree with the terms and conditions, Please do not click
          on the ACCEPT button found on the registration page on the Leja Mobile
          Application.
        </p>
        <p>
          You will be deemed to have been made aware of, will be subject to, and
          to have accepted these terms and conditions:-
          <ul>
            <li>
              Upon clicking on the “Accept” option on the Registration screen
              requesting you to confirm that you have read, understood and
              agreed to abide with these Terms and Conditions; and/or-
            </li>
            <li>
              By using or continuing to use and operate the Leja mobile
              Application or any other service offered by Asilimia.
            </li>
          </ul>
        </p>
        <p>
          Asilimia reserves the right to amend or update these terms and
          conditions from time to time and for any reason.
        </p>
        <p>
          The company will alert you about any changes by updating the “Last
          updated” date of these terms and conditions.
        </p>
        <p>
          You are encouraged to periodically review these terms and conditions
          to stay informed of updates. You will be deemed to have been made
          aware of, will be subject to, and to have accepted the changes in any
          revised terms and conditions by your continued use of the Application
          after the date revised copy is posted.
        </p>
      </Box>

      <Box sx={heading}>4. Account Opening</Box>
      <Box m={1} sx={paragraph}>
        <p m={1}>
          In order to open a Leja account with Asilimia, you must be at least 18
          years old with legal capacity to contract, and a registered and active
          Mobile Subscriber with a mobile service provider with which the
          Company has an agreement with for the provision of this service.
        </p>
        <p m={1}>
          To avail the Services, a User would be required to create an account
          on the Platform using his/her phone number among other details.
        </p>
        <p m={1}>
          We want to inform you that Leja may require permissions to use either
          your email address account or Facebook account while opening an
          account with Us.
        </p>
        <p m={1}>
          You may open a <strong>Leja</strong> Account solely by way of an
          electronic application made by you using your Equipment via the{" "}
          <strong>Leja</strong> Mobile Application on the Registration or Sign
          Up screen.
        </p>
        <p m={1}>
          The Company may require you to click on the “Accept” button on the
          Registration screen requesting you to confirm that you have read,
          understood and agreed to abide with these Terms and Conditions.
        </p>
        <p m={1}>
          You hereby further acknowledge and authorize the Company to verify
          your Personal Information as received from the Registration Process.
        </p>
        <p>
          The company reserves the right to request for further information from
          you pertaining to your application for a <strong>Leja</strong> account
          at any time. Failure to provide such information within the time
          required by the Company may result in the Company declining to accept
          your application for a Leja account.
        </p>
        <p m={1}>
          Acceptance by the Company of your application to open an account with
          <strong>Leja</strong> shall be done via SMS sent to the Mobile Phone
          Number associated with Leja Account. You acknowledge and accept that
          the acceptance by the company of your application for a{" "}
          <strong>Leja</strong> account does not create any contractual
          relationship between you and the Mobile Network beyond the terms and
          conditions that apply to your <strong>Leja</strong> Account from time
          to time.
        </p>
        <p m={1}>
          Asilimia reserves the right to decline your application for a{" "}
          <strong>Leja Account </strong> or to revoke the same at any stage at
          the Company’s sole discretion and without assigning any reason or
          giving any notice thereto.
        </p>
      </Box>
      <Box sx={heading}>5. Our Services</Box>
      <Box sx={paragraph} m={1}>
        Asilimia offers the following services through Leja:-
        <ul>
          <li>
            <strong>5.1 Digital Book Keeping</strong>
          </li>
          <p sx={paragraph} m={1}>
            Leja provides users with a platform to digitally record their debit
            and credit transactions with their respective customers. The Users
            may upload information relating to transactions with their customers
            or relating to their businesses, including the sale of goods or
            services, costs, amounts paid and payable, and details of goods and
            services, on the Platform as shown below;
          </p>
          <img
            style={{
              height: "auto",
              width: "50%",
            }}
            src={bookKeeping}
            alt="screenshot"
          />
          <li>
            <strong>5.2 Send Money</strong>
          </li>
          <p sx={paragraph}>
            This feature provides users with a platform to send money to other
            MPESA users at an agreed transaction fee. Users can track their
            transaction history from the app.
          </p>
          <p>
            You will be required to grant access to your contact list{" "}
            <strong>SHOULD</strong> you choose to select a contact from your
            contact list within the application.
          </p>
          <li>
            <strong>5.3 Buy Airtime</strong>
          </li>
          <p sx={paragraph}>
            This feature allows users to recharge airtime to their or other
            mobile network users using local Based mobile service providers;
            Safaricom, Airtel and Telkom sim cards. Users can track their
            transaction history from the app.
          </p>
          <p>
            You will be required to grant access to your contact list{" "}
            <strong>SHOULD</strong> you choose to select a contact from your
            contact list within the application.
          </p>
          <li>
            <strong>5.4 Borrow Loans</strong>
          </li>
          <p sx={paragraph}>
            Leja provides microbusinesses with access to loans that are of low
            interest rates. The types of loans available on Leja include{" "}
            <strong>Airtime loans</strong> and <strong>Business loans</strong> .
            Business loans are further classified into
            <strong> Instant mobile loans</strong> (amount is deposited directly
            to user’s registered MPESA number) and{" "}
            <strong>Business loans</strong> (allows users to pay for goods and
            services directly to their suppliers).
          </p>
          <p sx={paragraph}>
            For the purposes of this clause, Services would include any other
            future services the Company provides/proposes to provide.
          </p>
        </ul>
      </Box>
      <Box m={1} sx={heading}>
        6. Fees
      </Box>
      <Box sx={paragraph} m={1}>
        You hereby agree to pay all Fees payable in connection with your use of
        the Services.
        <p>You shall pay to the Company:</p>
        <ul>
          <li>
            any Transaction Fees payable in respect of the Mobile Money
            Services;
          </li>
          <li>
            any Facility or Interest Fees payable in respect of the Mobile Money
            Services;
          </li>
          <li>
            any legal charges including advocate and client costs incurred by
            the Company in obtaining legal advice in connection with your Leja
            Account and your dealings with the Company or incurred by the
            Company in any legal, arbitration or other proceedings arising out
            of any dealings in respect of yourLeja Account; and
          </li>
          <li>
            all other fees, expenses and taxes, duties, impositions and expenses
            incurred in complying with your Requests
          </li>
        </ul>
      </Box>
      <Box m={1} sx={heading}>
        7. Statements
      </Box>
      <Box m={1} sx={paragraph}>
        <ul>
          <li>
            You may view an activity report or a mini-statement in respect to
            your Leja account using your equipment.
          </li>
          <li>
            A Mobile Money Mini Statement shall provide details of the last 5
            (five) transactions (or such other number of transactions as
            determined by Asilimia) in your Leja account initiated from your
            Equipment.
          </li>
          <li>
            You may obtain an electronic copy of your account statement via
            email upon sending a request through the Company’s customer care
            center.
          </li>
          <li>
            A user can also access a summary of their digitally recorded
            transactions on Leja from their equipment.
          </li>
        </ul>
      </Box>
      <Box>{/* <img alt="Consent" src={consentImg} /> */}</Box>

      <Box m={1} sx={heading}>
        8. User Responsibilities
      </Box>
      <Box sx={paragraph} m={1}>
        <p>
          Users of our services have substantial obligations with respect to
          their own data and how they use their own equipment and maintain
          security from their end. The company may require users to:-
          <ul>
            <li>
              provide valid, correct and honest information on the date of
              agreeing to these terms and conditions. The Company does not bear
              any responsibility for any damage or loss a user may incur if any
              information, data and materials shared in regards to the provision
              of our service is incorrect, incomplete or invalid.
            </li>
            <li>
              maintain appropriate security for your password, account number
              and other information you file with us or receive from us
              regarding your account.
            </li>
            <li>
              familiarize yourself with the operating procedures for the service
              as will be provided by the Company upon your registration to the
              Service. The Company will not be liable for any losses incurred as
              a result of your errors either of commission and/or omission.
            </li>
            <li>
              When you irrevocably authorize the Company to act on all Requests
              received by the Company from you (or purportedly from you) through
              the System and to hold you liable in respect thereof,
              notwithstanding that any such requests are not authorized by you
              or are not in accordance with any existing mandates given by you.
            </li>
            <li>
              If you request the Company to cancel any transaction or
              instruction after a Request has been received by the Company from
              you, the Company may at its absolute discretion cancel such
              transaction or instruction but shall have no obligation to do so.
            </li>
            <li>
              You shall at your own expense provide and maintain in safe and
              efficient operating order your Equipment necessary for the purpose
              of accessing the System and the Services.
            </li>
            <li>
              You shall be responsible for ensuring the proper performance of
              your Equipment. The Company shall neither be responsible for any
              errors or failures caused by any malfunction of your Equipment,
              and nor shall the Company be responsible for any computer virus or
              related problems that may be associated with the use of the
              System, the Services and the Equipment.{" "}
            </li>
            <li>
              You shall be responsible for charges due to any service provider
              providing you with connection to the Network and the Company shall
              not be responsible for losses or delays caused by any such service
              provider.
            </li>
          </ul>
        </p>
        <p>
          In the event of any conflict between any terms of any Request received
          by the Company from you and these Terms and Conditions, these Terms
          and Conditions shall prevail.
        </p>
      </Box>

      <Box m={1} sx={heading}>
        9. Intellectual Property Rights
      </Box>
      <Box sx={paragraph} m={1}>
        You acknowledge that the intellectual property rights in the System (and
        any amendments, upgrades or enhancements thereto from time to time) and
        all associated documentation that the Company provides to you through
        the System or otherwise are vested either in the Company or in other
        persons from whom the Company has a right to use and to sub-license the
        System and/or the said documentation. You shall not infringe any such
        intellectual property rights. You shall not duplicate, reproduce or in
        any way tamper with the System and associated documentation without the
        prior written consent of the Company.
      </Box>

      <Box m={1} sx={heading}>
        10. Indemnity
      </Box>
      <Box sx={paragraph} m={1}>
        You waive and shall not assert any claims or allegations of any nature
        whatsoever against Asilimia, its affiliates or subsidiaries, their
        sponsors, contractors, advertisers, vendors or other partners, any of
        their successors or assigns, or any of their respective officers,
        directors, agents or employees (collectively, the “Released Parties”)
        arising out of or in any way relating to your use of the Leja and other
        products owned by the Company or any Third Party Materials or Third
        Party Services, including, without limitation, any claims or allegations
        relating to infringement of proprietary rights, or allegations that any
        Released Party has or should indemnify, defend or hold harmless you or
        any third party from any claim or allegation arising from your access,
        use or other exploitation of Our services, violation of these Terms or
        any infringement by any third party who may use your account with the
        Company or any Third Party Materials or Third Party Services.
      </Box>

      <Box m={1} sx={heading}>
        11. Termination
      </Box>
      <Box sx={paragraph} m={1}>
        <ul>
          <li>
            Asilimia Terms of service shall remain in effect unless a user is
            terminated upon user’s request to terminate the account or due to
            violation of these terms and conditions.
          </li>
          <li>
            Either party may terminate their obligations under these conditions
            at any time on written notice or by word of mouth communicated via
            the Company’s Contact Centre.
          </li>
          <li>
            On termination by the account holder the termination notice should
            be accompanied by the repayment of the full loan outstanding at the
            time of receipt. Equally, the Company shall transfer any shares of
            monetary value owed to the user.
          </li>
          <li>
            The Company may terminate a User’s access to or use of the Services,
            or any portion thereof, immediately and at any point, at its sole
            discretion, if the User violates or breaches any of its obligations,
            responsibilities, or covenants under these Terms.
          </li>
          <li>
            Termination by either the User or the Company shall not affect the
            User’s obligations to meet any liabilities incurred prior to such
            termination.
          </li>
          <li>
            This condition persists until the User has repaid all amounts
            outstanding at the time of termination.
          </li>
        </ul>
      </Box>

      <Box m={1} sx={heading}>
        12. Disclosure of Information
      </Box>
      <Box sx={paragraph} m={1}>
        A Leja User agrees that Asilimia may disclose details of the relating to
        his/her account to any Third party(including credit agencies) if in the
        Company’s opinion such disclosure is necessary for the purpose of
        evaluating any application made to to the Company or such Third Party or
        maintaining the operative account with the Company or other purpose as
        the Company shall deem appropriate.
      </Box>

      <Box m={1} sx={heading}>
        13. Notices
      </Box>
      <Box sx={paragraph} m={1}>
        <p>
          All notices to the User made under these terms and conditions shall be
          sent via a short message service to the mobile telephone number or by
          email provided by the User.
        </p>
        <p>
          A user may reach the Company by writing to the Company’s email{" "}
          <a href="mailto: support@asilimia.co.ke"> info@asilimia.co.ke </a>
          or through our call center{" "}
          <a href="tel: +254 111 052280"> +254 111 052280</a>
        </p>
      </Box>

      <Box m={1} sx={heading}>
        14. Dispute Resolution, Jurisdiction And Arbitration
      </Box>
      <Box sx={paragraph} m={1}>
        <ul>
          <li>
            You may contact the Contact Center to report any disputes, claims or
            Leja discrepancies.{" "}
          </li>
          <li>
            Any dispute arising between the parties and which is not amicably
            resolved shall be subject to the Courts of Kenya at the election of
            either party.
          </li>
        </ul>
      </Box>

      <Footer />
    </Container>
  );
};
export default TermsAndConditions;
