/** @format */

import React from 'react';
import { Navbar } from '../../components/navigation/navbar';
import Footer from '../../components/footer/footer';
import { Container, Stack, Box } from '@mui/material';

const title = {
	textAlign: 'center',
	fontWeight: '800',
	color: '#000000',
	fontSize: '25px',
	fontFamily: 'Rubik',
	marginTop: '2rem',
};

const heading = {
	fontWeight: '700',
	fontSize: '23px',
	fontFamily: 'Rubik',
	color: '#009966',
	textAlign: 'left',
};
const paragraph = {
	fontWeight: '390',
	fontSize: '16px',
	fontFamily: 'Rubik',
	color: '#000000',
	marginTop: '1rem',
	marginLeft: '1.5rem',
	marginBottom: '0.6rem',
};
const date = {
	fontWeight: '400',
	fontSize: '14px',
	fontFamily: 'Rubik',
	color: '#000000',
};
const PrivacyPolicy = () => {
	return (
		<Container>
			<Navbar />
			<Box
				variant='h4'
				gutterBottom
				sx={{
					marginTop: '5rem',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Box
					variant='h2'
					gutterBottom
					sx={title}>
					LEJA DATA PRIVACY POLICY
				</Box>
				<Box sx={date}>Last updated September 20, 2023</Box>
			</Box>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				mb={5}></Stack>
			<Box
				fontWeight='Bold'
				m={1}
				sx={heading}>
				Introduction
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<span>
					<strong>LEJA</strong>
				</span>{' '}
				mobile application (the “Service”).
				<p>
					This Policy is used to inform visitors regarding our policies with the
					collection, use, and disclosure of Personal Information if anyone
					decided to use our Service. If you choose to use our Service, then you
					agree to the collection and use of information in relation to this
					policy. The Personal Information that we collect is used for providing
					and improving the Service. We will not use or share your information
					with anyone except as described in this Privacy Policy.
				</p>
				<p>
					The terms used in this Privacy Policy have the same meanings as in our
					Terms and Conditions, which is accessible herein unless otherwise
					defined in this Privacy Policy.
				</p>
				<p>
					{' '}
					"<strong>Personal data</strong>" means any information relating to an
					identified or identifiable natural person.
				</p>
				<p>
					{' '}
					"<strong>Sensitive personal data</strong>" means data that reveals the
					natural person’s race, health status, ethnic, social origin,
					conscience, belief, genetic data, biometric data, property details,
					marital status, family details including names of the person’s
					children, parents, spouse or spouses sex, or the sexual orientation of
					the data subject.
				</p>
				<p>
					{' '}
					"<strong>Processing data</strong>" means any operation or sets of
					operations performed on personal data whether or not by automated
					means, such as;
					<ul>
						<li> collection, recording, organization, structuring;</li>
						<li> storage, adaptation or alteration;</li>
						<li>retrieval, consultation or use;</li>
						<li>
							disclosure by transmission, dissemination, or otherwise making
							available; or
						</li>
						<li>
							{' '}
							alignment or combination, restriction, erasure or destruction
						</li>
					</ul>
				</p>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Policy statement
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				We are committed to complying with all relevant Kenyan legislation and
				applicable global legislations. We recognize that the protection of
				individuals through lawful, legitimate, and responsible processing and
				use of their personal data is a fundamental human right. We will ensure
				that it protects the rights of data subjects and that the data it
				collects, and processes is done in line with the required legislation.
				Our staff must comply with this policy, breach of which could result in
				disciplinary action.
			</Box>
			<Box
				m={1}
				sx={heading}>
				Our Principles
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				We will ensure that data is:
				<ul>
					<li>
						Processed lawfully, fairly and in a transparent manner and in line
						with the right to privacy.
					</li>
					<li>
						Collected only for specified, explicit and legitimate purposes and
						not further processed in a manner incompatible with that purpose.
					</li>
					<li>
						Adequate, relevant, and limited to what is necessary in relation to
						the purposes for which it is to be processed.
					</li>
					<li>Accurate and where necessary kept up to date.</li>
					<li>
						Not kept in a form which permits identification of data subjects for
						longer than is necessary for the purposes for which the data is
						processed.
					</li>
					<li>
						Processed in a manner that ensures its security using appropriate
						technical and organizational measures to protect against
						unauthorized or unlawful processing and accidental loss,
						destruction, or damage.
					</li>
					<li>
						Not transferred out of Kenya unless there is proof of adequate data
						safeguards/ measures or consent from the data subject.
					</li>
				</ul>
			</Box>

			<Box
				m={1}
				sx={heading}>
				Information we collect about you and your device
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				We may collect information about you in a variety of ways. The
				information we may collect via the Application depends on the content
				and materials you use, and includes:
				<ol>
					<li>
						<strong>Personal Data</strong>
					</li>
					<p>
						Personal Data, Sensitive Personal Data, Demographic and other
						personally identifiable information (such as your name and email
						address) that you voluntarily give to us when choosing to
						participate in various activities related to the Application, such
						as chat, posting messages in comment sections or in our forums,
						liking posts, sending feedback, and responding to surveys. If you
						choose to share data about yourself via your profile, online chat,
						or other interactive areas of the Application, please be advised
						that all data you disclose in these areas is public and your data
						will be accessible to anyone who accesses the Application.
					</p>
					<li>
						<strong>Derivative Data</strong>
					</li>
					<p>
						Information our servers automatically collect when you access the
						Application, such as your native actions that are integral to the
						Application, including liking, re-blogging, or replying to a post,
						as well as other interactions with the Application and other users
						via server log files.
					</p>
					<li>
						<strong>Financial Data</strong>
					</li>
					<p>
						Financial information, such as data related to your payment method
						(e.g. valid credit card number, card brand, expiration date) that we
						may collect when you purchase, order, return, exchange, or request
						information about our services from the Application. [We store only
						very limited, if any, financial information that we collect.
						Otherwise, all financial information is stored by our payment
						processor, <strong>MPESA</strong>, and you are encouraged to review
						their privacy policy and contact them directly for responses to your
						questions.
					</p>
					<li>
						<strong>Facebook Permissions</strong>
					</li>
					<p>
						The Application may by default access your{' '}
						<a
							href='https://www.facebook.com/about/privacy/'
							target='_blank'
							rel='noreferrer'>
							Facebook
						</a>{' '}
						basic account information, including your name, email, gender,
						birthday, current city, and profile picture URL, as well as other
						information that you choose to make public. We may also request
						access to other permissions related to your account, such as
						friends, check ins, and likes, and you may choose to grant or deny
						us access to each individual permission. For more information
						regarding Facebook permissions, refer to the{' '}
						<a
							href='https://developers.facebook.com/docs/permissions/reference'
							target='_blank'
							rel='noreferrer'>
							Facebook Permissions Reference{' '}
						</a>
						page.
					</p>
					<li>
						<strong>Data from Social Networks</strong>
					</li>
					<p>
						User information from social networking sites, such as [Apple’s Game
						Center, Facebook, Google+ Instagram, Pinterest, Twitter], including
						your name, your social network username, location, gender, birth
						date, email address, profile picture, and public data for contacts,
						if you connect your account to such social networks. This
						information may also include the contact information of anyone you
						invite to use and/or join the Application.
					</p>
					<li>
						<strong>Geo-Location Information</strong>
					</li>
					<p>
						We may request access or permission to and track location-based
						information from your mobile device, either continuously or while
						you are using the Application, to provide location-based services.
						If you wish to change our access or permissions, you may do so in
						your device’s settings.
					</p>
					<li>
						<strong>Mobile Device Access</strong>
					</li>
					<p>
						We may request access or permission to certain features from your
						mobile device, including your mobile device’s [camera, contacts, SMS
						messages, social media accounts, storage, location] and other
						features.
					</p>
					<ul>
						<li>
							READ SMS permission is required to aid your loan appraisal during
							loan application. Upon the users’ consent, READ SMS permission is
							also required to enable the user to view a detailed transaction
							summary of MPESA transactions within the application when a
							transaction is completed. Leja application will only read and
							upload and update SMS originating from MPESA.
						</li>
						<li>
							CAMERA permission will be required to upload demographic data
							during the loan application process which will be used in your
							loan appraisal. CAMERA permission is also required to enable the
							user to capture and upload photos of their businesses during the
							business wallet application.
						</li>
						<li>
							READ CONTACT permission will be required if you wish to select a
							contact from your phone’s contact list to initiate a transaction
							within the application.
						</li>
						<li>
							STORAGE permission is required to upload files through the
							application. STORAGE permission is also required to enable the
							user to upload photos of their businesses during the business
							wallet application.
						</li>
					</ul>

					<p>
						If you wish to change our access or permissions, you may do so in
						your device’s settings.
					</p>
					<li>
						<strong>Mobile Device Data</strong>
					</li>
					<p>
						Device information such as your mobile device ID number, model, and
						manufacturer, version of your operating system, phone number,
						country, location, and any other data you choose to provide.
					</p>
					<li>
						<strong>Push Notifications</strong>
					</li>
					<p>
						We may request to send you push notifications regarding your account
						or the Application. If you wish to opt-out from receiving these
						types of communications, you may turn them off in your device’s
						settings.
					</p>
					<li>
						<strong>App background data</strong>
					</li>
					<p>
						Leja application will only read and upload and update SMS
						originating from MPESA for:
						<ol>
							<li>Credit scoring to determine your loan limit</li>
							<li>Fraud detection</li>
							<li>
								Displaying a financial summary of M-PESA transactions alongside
								Leja in-app transactions.
							</li>
						</ol>
						SMS data collection will begin upon user consent and every
						subsequent app launch.
					</p>
					<p>
						{' '}
						Leja collects the device make and model when the app is in the
						foreground and is used in debugging errors that may occur within the
						application. All data collection requested will begin only upon user
						consent.
					</p>
				</ol>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Information we receive from other sources
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				Due to the nature of the Services, which we provide, we are required to
				work with a number of third parties (including credit reference agencies
				and mobile network providers) and we may receive information about you
				from them. They may include:
				<ol>
					<li>
						<strong>Third-Party Data</strong>
					</li>
					<p>
						Information from third parties, such as personal information or
						network friends, if you connect your account to the third party and
						grant the Application permission to access this information.
					</p>
					<li>
						<strong>Data From Contests, Giveaways, and Surveys</strong>
					</li>
					<p>
						Personal and other information you may provide when entering
						contests or giveaways and/or responding to surveys.
					</p>
				</ol>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Why We Collect Data
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					We collect certain data to be able to provide the Service to you.
					Having accurate information about you permits us to provide you with a
					smooth, efficient, and customized experience. We also collect data for
					use in verifying your identity and creating credit-scoring models to
					determine what Loans can be offered to you. We also use this data for
					purposes of collections and credit reporting.
				</p>
				<p>
					<b>LEJA</b> uses the collected data for various purposes:
				</p>
				<ul>
					<li>To provide and maintain the Service.</li>
					<li>To notify you about changes to our Service.</li>
					<li>
						To allow you to participate in interactive features of our Service
						when you choose to do so.
					</li>
					<li>To provide customer care and support.</li>
					<li>
						To provide analysis or valuable information so that we can improve
						the Service.
					</li>
					<li>To monitor the usage of the Service</li>
					<li>To detect, prevent and address technical issues.</li>
					<li> Administer sweepstakes, promotions, contests.</li>
					<li>
						Compile anonymous statistical data and analysis for use internally
						or with third parties.
					</li>
					<li>Create and manage your account.</li>
					<li>
						Deliver targeted advertising, coupons, newsletters, and other
						information regarding promotions and the Application to you.
					</li>
					<li>Email you regarding your account or order</li>
					<li>
						Fulfill and manage purchases, orders, payments, and other
						transactions related to the Application.
					</li>
					<li>
						Generate a personal profile about you to make future visits to the
						Application more personalized.
					</li>
					<li>Increase the efficiency and operation of the Application.</li>
					<li>
						Monitor and analyze usage and trends to improve your experience with
						the Application
					</li>
					<li>Notify you of updates to the Application.</li>
					<li>
						Offer new products, services, mobile applications, and/or
						recommendations to you.
					</li>
					<li>Perform other business activities as needed.</li>
					<li>
						Prevent fraudulent transactions, monitor against theft, and protect
						against criminal activity.
					</li>
					<li>Process payments and refunds.</li>
					<li>
						Request feedback and contact you about your use of the Application.{' '}
					</li>
					<li>Resolve disputes and troubleshoot problems.</li>
					<li>Respond to product and customer service requests.</li>
					<li>Send you a newsletter.</li>
					<li>Solicit support for the Application.</li>
				</ul>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Processing sensitive personal data
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>We will process sensitive personal data only when:</p>
				<p>
					The processing is carried out in the course of legitimate activities
					with appropriate safeguards and that the processing relates solely to
					the staff or to persons who have regular contact with us, and the
					personal data is not disclosed outside Leja without the consent of the
					data subject.
				</p>
				<p>
					The processing relates to personal data that has been made public by
					the data subject.
				</p>
				<p>
					Processing is necessary for:
					<ul>
						<li>The establishment, exercise or defense of a legal claim</li>
						<li>
							The purpose of carrying out the obligations and exercising
							specific rights of the controller or of the data subject
						</li>
						<li>
							Protecting the vital interests of the data subject or another
							person where the data subject is physically or legally incapable
							of giving consent.
						</li>
					</ul>
				</p>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Where we store your personal data
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				The data that we collect from you may be transferred to, and stored at,
				a destination outside your country of origin or residence (as
				applicable) or other governmental jurisdiction where the data protection
				laws may differ from those from your jurisdiction.
				<p>
					If you are located outside KENYA and choose to provide information to
					us, please note that we transfer the data, including Personal Data, to
					KENYA and process it there.
				</p>
				<p>
					We will transfer personal data out of Kenya only when they have:
					<ul>
						<li>
							{' '}
							Proof of appropriate measures for security and protection of the
							personal data, and the proof provided to the Data Protection
							Commissioner in accordance with Kenya’s Data Protection Act, 2019,
							such measures include that data is transferred to jurisdictions
							with commensurate data protection laws.
						</li>
						<li>
							{' '}
							The transfer is necessary for the performance of a contract,
							implementation of pre- contractual measures such as:
							<ul>
								<li>
									For the conclusion or performance of a contract to which the
									data subject is part of.
								</li>
								<li>For matters of public interest.</li>
								<li>For legal claims.</li>
							</ul>
						</li>
					</ul>
				</p>
				<p>To protect the vital interests of data subjects</p>
				<p>
					Your consent to this Privacy Policy followed by your submission of
					such information represents your agreement to that transfer.
				</p>
				<p>
					Leja will take all steps reasonably necessary to ensure that your data
					is treated securely and in accordance with this Privacy Policy and no
					transfer of your Personal Data will take place to an organization or a
					country unless there are adequate controls in place including the
					security of your data and other personal information.
				</p>
			</Box>
			<Box
				sx={heading}
				m={1}>
				Consent
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				You are required to give consent immediately you install the Leja app
				and before using the service on the Registration page shown below. Where
				necessary we will maintain adequate records to show that consent was
				obtained before processing your personal information. Data will not be
				processed after the withdrawal of your consent.
			</Box>
			<Box>{/* <img alt="Consent" src={consentImg} /> */}</Box>

			<Box
				fontWeight='fontWeightBold'
				m={1}
				sx={heading}>
				Log Data
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				We want to inform you that whenever you use our Service, in the case of
				an error in the app we collect data and information (through third party
				products) on your phone called Log Data. This Log Data may include
				information such as your device Internet Protocol (“IP”) address, device
				name, operating system version, the configuration of the app when
				utilizing our Service, the time and date of your use of the Service, and
				other statistics.
			</Box>

			<Box
				m={1}
				sx={heading}>
				Disclosure of Data
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					We may share information we have collected about you in certain
					situations. Your information may be disclosed as follows:
				</p>
				<ol>
					<li>
						<strong>By Law or to Protect Rights</strong>
					</li>
					<p>
						If we believe the release of information about you is necessary to
						respond to legal process, to investigate or remedy potential
						violations of our policies, or to protect the rights, property, and
						safety of others, we may share your information as permitted or
						required by any applicable law, rule, or regulation. This includes
						exchanging information with other entities for fraud protection and
						credit risk reduction.
					</p>
					<li>
						<strong>Third-Party Service Providers</strong>
					</li>
					<p>
						We may share your information with third parties that perform
						services for us or on our behalf, including payment processing, data
						analysis, email delivery, hosting services, customer service, and
						marketing assistance.
					</p>
					<li>
						<strong>Marketing Communications</strong>
					</li>
					<p>
						With your consent, or with an opportunity for you to withdraw
						consent, we may share your information with third parties for
						marketing purposes, as permitted by law.
					</p>
					<li>
						<strong>Interactions with Other Users</strong>
					</li>
					<p>
						If you interact with other users of the Application, those users may
						see your name, profile photo, and descriptions of your activity,
						including sending invitations to other users, chatting with other
						users, liking posts, following blogs.
					</p>
					<li>
						<strong>Online Postings</strong>
					</li>
					<p>
						When you post comments, contributions or other content to the
						Applications, your posts may be viewed by all users and may be
						publicly distributed outside the Application in perpetuity.
					</p>
					<li>
						<strong>Third-Party Advertisers</strong>
					</li>
					<p>
						We may use third-party advertising companies to serve ads when you
						visit the Application. These companies may use information about
						your visits to the Application and other websites that are contained
						in web cookies in order to provide advertisements about goods and
						services of interest to you.
					</p>
					<li>
						<strong>Affiliates</strong>
					</li>
					<p>
						We may share your information with our affiliates, in which case we
						will require those affiliates to honor this Privacy Policy.
						Affiliates include our parent company and any subsidiaries, joint
						venture partners or other companies that we control or that are
						under common control with us.
					</p>
					<li>
						<strong>Business Partners</strong>
					</li>
					<p>
						We may share your information with our business partners to offer
						you certain products, services or promotions.
					</p>
					<li>
						<strong>Offer Wall</strong>
					</li>
					<p>
						The Application may display a third-party-hosted “offer wall.” Such
						an offer wall allows third-party advertisers to offer virtual
						currency, gifts, or other items to users in return for acceptance
						and completion of an advertisement offer. Such an offer wall may
						appear in the Application and be displayed to you based on certain
						data, such as your geographic area or demographic information. When
						you click on an offer wall, you will leave the Application. A unique
						identifier, such as your user ID, will be shared with the offer wall
						provider in order to prevent fraud and properly credit your account.
					</p>
					<li>
						<strong>Social Media Contacts</strong>
					</li>
					<p>
						If you connect to the Application through a social network, your
						contacts on the social network will see your name, profile photo,
						and descriptions of your activity.
					</p>
					<li>
						<strong>Other Third Parties</strong>
					</li>
					<p>
						We may share your information with advertisers and investors for the
						purpose of conducting general business analysis. We may also share
						your information with such third parties for marketing purposes, as
						permitted by law.
					</p>
					<li>
						<strong>Sale or Bankruptcy</strong>
					</li>
					<p>
						If we reorganize or sell all or a portion of our assets, undergo a
						merger, or are acquired by another entity, we may transfer your
						information to the successor entity. If we go out of business or
						enter bankruptcy, your information would be an asset transferred or
						acquired by a third party. You acknowledge that such transfers may
						occur and that the transferee may decline honor commitments we made
						in this Privacy Policy.
					</p>
					<p>
						We are not responsible for the actions of third parties with whom
						you share personal or sensitive data, and we have no authority to
						manage or control third-party solicitations. If you no longer wish
						to receive correspondence, emails or other communications from third
						parties, you are responsible for contacting the third party
						directly.
					</p>
				</ol>
			</Box>

			<Box
				m={1}
				sx={heading}>
				Legal Requirements;
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<strong>Leja </strong> may disclose your Personal Data in the good faith
				belief that such action is necessary to:
				<li>To comply with a legal obligation</li>
				<li>
					To protect and defend the rights or property of{' '}
					<strong>Leja</strong>
				</li>
				<li>
					To prevent or investigate possible wrongdoing in connection with the
					Service
				</li>
				<li>
					To protect the personal safety of users of the Service or the public
				</li>
				<li>To protect against legal liability</li>
			</Box>
			<Box sx={heading}>Tracking Technologies</Box>
			<Box sx={paragraph}>
				<ol>
					<li>
						<strong>Cookies and Web Beacons</strong>
					</li>
					<p>
						We may use cookies, web beacons, tracking pixels, and other tracking
						technologies on the Application to help customize the Application
						and improve your experience. When you access the Application, your
						personal information is not collected through the use of tracking
						technology. Most browsers are set to accept cookies by default. You
						can remove or reject cookies, but be aware that such action could
						affect the availability and functionality of the Application. You
						may not decline web beacons. However, they can be rendered
						ineffective by declining all cookies or by modifying your web
						browser’s settings to notify you each time a cookie is tendered,
						permitting you to accept or decline cookies on an individual basis.
					</p>
					<li>
						<strong>Internet-Based Advertising</strong>
					</li>
					<p>
						Additionally, we may use third-party software to serve ads on the
						Application, implement email marketing campaigns, and manage other
						interactive marketing initiatives. This third-party software may use
						cookies or similar tracking technology to help manage and optimize
						your online experience with us. For more information about
						opting-out of interest-based ads, visit the Network Advertising
						Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out
						Tool.
					</p>
					<li>
						<strong>Website Analytics</strong>
					</li>
					<p>
						We may also partner with selected third-party vendors[, such as
						[Adobe Analytics,] [Clicktale,] [Clicky,] [Cloudfare,] [Crazy Egg,]
						[Flurry Analytics,] [Google Analytics,] [Heap Analytics,]
						[Inspectlet,] [Kissmetrics,] [Mixpanel,] [Piwik,] and others], to
						allow tracking technologies and remarketing services on the
						Application through the use of first party cookies and third-party
						cookies, to, among other things, analyze and track users’ use of the
						Application, determine the popularity of certain content, and better
						understand online activity. By accessing the Application, you
						consent to the collection and use of your information by these
						third-party vendors. You are encouraged to review their privacy
						policy and contact them directly for responses to your questions. We
						do not transfer personal information to these third-party vendors.
						However, if you do not want any information to be collected and used
						by tracking technologies, you can install and/or update your
						settings for one of the following:
					</p>
					<ul>
						<li> [Adobe Privacy Choices Page]</li>
						<li>[Clicktale Opt-Out Feature]</li>
						<li>[Crazy Opt-Out Feature]</li>
						<li>Digital Advertising Alliance Opt-Out Tool</li>
						<li>[Flurry Analytics Yahoo Opt-Out Manager]</li>
						<li>[Google Analytics Opt-Out Plugin]</li>
						<li>[Google Ads Settings Page]</li>
						<li>[Inspectlet Opt-Out Cookie]</li>
						<li>[Kissmetrics Opt-Out Feature]</li>
						<li>[Mixpanel Opt-Out Cookie]</li>
						<li>Network Advertising Initiative Opt-Out Tool</li>
					</ul>
					<p>
						You should be aware that getting a new computer, installing a new
						browser, upgrading an existing browser, or erasing or otherwise
						altering your browser’s cookies files may also clear certain opt-out
						cookies, plug-ins, or settings.
					</p>
				</ol>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Third Party Websites
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				The Application may contain links to third-party websites and
				applications of interest, including advertisements and external
				services, that are not affiliated with us. Once you have used these
				links to leave the Application, any information you provide to these
				third parties is not covered by this Privacy Policy, and we cannot
				guarantee the safety and privacy of your information. Before visiting
				and providing any information to any third-party websites, you should
				inform yourself of the privacy policies and practices (if any) of the
				third party responsible for that website, and should take those steps
				necessary to, in your discretion, protect the privacy of your
				information. We are not responsible for the content or privacy and
				security practices and policies of any third parties, including other
				sites, services or applications that may be linked to or from the
				Application.
			</Box>
			<Box
				m={1}
				sx={heading}>
				Security
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					Personal Information will be protected by security safeguards that are
					appropriate to the sensitivity level of the information will protect
					personal Information. We take all reasonable precautions to protect
					your Personal Information from any loss or unauthorized use, access or
					disclosure. Except for the aforementioned, we shall not share the
					provided data with any other third parties.
				</p>

				<p>
					The security of your data is important to us, but remember that no
					method of transmission over the Internet, or method of electronic
					storage is 100% secure. While we strive to use commercially acceptable
					means to protect your Personal Data, we cannot guarantee its absolute
					security.
				</p>
			</Box>

			<Box
				m={1}
				sx={heading}>
				Service Providers
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					We may employ third party companies and individuals to facilitate our
					Service (“Service Providers”), to provide the Service on our behalf,
					to perform Service-related services or to assist us in analyzing how
					our Service is used.
				</p>
				<p>
					These third parties have access to your Personal Data only to perform
					these tasks on our behalf and are obligated not to disclose or use it
					for any other purpose.
				</p>
			</Box>

			<Box
				m={1}
				sx={heading}>
				Changes to This Privacy Policy
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				We may update our Privacy Policy from time to time. Thus, you are
				advised to review this page periodically for any changes. We will notify
				you of any changes by posting the new Privacy Policy on this page. These
				changes are effective immediately after they are posted on this page.
			</Box>
			<Box
				m={1}
				sx={heading}>
				Children’s Privacy
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				Our Service does not address anyone under the age of 18 (“Children”). We
				do not knowingly collect personally identifiable information or market
				to children under the age of 18. If you are a parent or guardian and you
				are aware that your Child(ren) has/have provided us with Personal Data,
				please contact us. If we become aware that we have collected Personal
				Data from children without verification of parental consent, we take
				steps to remove that information from our servers.
			</Box>
			<Box
				m={1}
				sx={heading}>
				Controls For Do-Not-Track Features
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				Most web browsers and some mobile operating systems [and our mobile
				applications] include a Do-Not-Track (“DNT”) feature or setting you can
				activate to signal your privacy preference not to have data about your
				online browsing activities monitored and collected. No uniform
				technology standard for recognizing and implementing DNT signals has
				been finalized. As such, we do not currently respond to DNT browser
				signals or any other mechanism that automatically communicates your
				choice not to be tracked online. If a standard for online tracking is
				adopted that we must follow in the future, we will inform you about that
				practice in a revised version of this Privacy Policy.
			</Box>
			<Box
				m={1}
				sx={heading}>
				Options regarding your Information
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					<strong>Account Information</strong>
				</p>
				<p>
					You may at any time review or change the information in your account
					or terminate your account by:
				</p>
				<ul>
					<li>Logging into your account settings and updating your account</li>
					<li>
						Contacting us officially using our support email:{' '}
						<a href='mailto: support@asilimia.co.ke'>
							{' '}
							support@asilimia.co.ke{' '}
						</a>
					</li>
          </ul>

					<p>
						Upon your request to terminate your account, we will deactivate or
						delete your account and information from our active databases.
						However, some information may be retained in our files to prevent
						fraud, troubleshoot problems, assist with any investigations,
						enforce our Terms of Use and/or comply with legal requirements.
					</p>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Emails and Communications
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					If you no longer wish to receive correspondence, emails, or other
					communications from us, you may opt-out by:
				</p>
				<ul>
					<li>
						{' '}
						Noting your preferences at the time you register your account with
						the Application
					</li>
					<li>
						Logging into your account settings and updating your preferences.
					</li>
					<li>Contacting us using the contact information provided below</li>
					<p>
						If you no longer wish to receive correspondence, emails, or other
						communications from third parties, you are responsible for
						contacting the third party directly.
					</p>
				</ul>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Onward reporting
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				In line with regulatory requirements, we will report to the Data
				Protection Commissioner and/or the relevant authorities any data breach
				within 72 hours of being aware. We will also communicate the data breach
				to the data subject as soon as is practical unless the identity of the
				data subject cannot be established.
			</Box>
			<Box
				m={1}
				sx={heading}>
				Data protection officer
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					We have a designated Data Protection Officer (DPO). Accordingly, the
					DPO will:
					<ul>
						<li>
							Advise our staff on requirements for data protection, including
							data protection impact assessments.
						</li>
						<li>
							{' '}
							Ensure that we have complied with the legal requirements on data
							protection.
						</li>
						<li>
							Facilitate capacity building of staff involved in data processing
							operations.
						</li>
						<li>
							Cooperate with external regulators on matters relating to data
							protection.
						</li>
					</ul>
				</p>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Minimization of collection
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				We will not process any personal data for a purpose for which it did not
				obtain consent. Should such a need arise, then consent must be obtained
				from you. We will collect and process data that is adequate, relevant,
				and limited to what is necessary. Our staff must not access data which
				they are not authorized to access nor have a reason to access. Data must
				only be collected for the performance of duties and tasks; staff must
				not ask data subjects to provide personal data unless that is strictly
				necessary for the intended purpose. Staff must ensure that they delete,
				destroy, or anonymise any personal data that is no longer needed for the
				specific purpose for which they were collected.
			</Box>
			<Box
				m={1}
				sx={heading}>
				Accuracy of data
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					We shall ensure that the personal data we collect and process is
					accurate, kept up to date, corrected or deleted without delay. All
					relevant records must be updated should staff be notified of
					inaccuracies. Inaccurate or out of date records must be deleted or
					destroyed.
				</p>
				<p>
					<strong>Data protection impact assessment</strong>
				</p>
				<p>
					We will undertake a data protection impact assessment whenever we
					identify that the processing of personal information will likely
					result in a high risk to your rights and freedoms.
				</p>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Disclaimer
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					We reserve the right to make changes to this Privacy Policy at any
					time and for any reason. We will alert you about any changes by
					updating the “Last updated” date of this Privacy Policy. You are
					encouraged to periodically review this Privacy Policy to stay informed
					of updates. You will be deemed to have been made aware of, will be
					subject to, and will be deemed to have accepted the changes in any
					revised Privacy Policy by your continued use of the Application after
					the date such revised Privacy Policy is posted. This Privacy Policy
					does not apply to the third-party online/mobile store from which you
					install the Application or make payments, including any in-game
					virtual items, which may also collect and use data about you. We are
					not responsible for any of the data collected by any such third party.
				</p>
			</Box>
			<Box
				m={1}
				sx={heading}>
				Contact Us
			</Box>
			<Box
				sx={paragraph}
				m={1}>
				<p>
					If you have questions or comments about this Privacy Policy, please
					contact us at:
				</p>
				<li>
					Noting your preferences at the time you register your account with the
					application
				</li>
				<li>
					{' '}
					Logging into your account settings and updating your preferences.
				</li>
				<li>Contacting us using the contact information provided below:</li>
				<p>Leja</p>
				<p
					rel='noopener'
					href='https://goo.gl/maps/F4s7XQMRnf6MPakw5'
					target={'_blank'}>
					Address Galana Road, 711 Kilimani, Nairobi
				</p>
				<p>
					<a href='tel: +254 111 052280'> +254 111 052280</a>
				</p>
				<p>
					<a href='mailto: info@asilimia.co.ke'> info@asilimia.co.ke </a>
				</p>
			</Box>
			<Footer />
		</Container>
	);
};
export default PrivacyPolicy;
