import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { TransparentNavbar } from "../../components/navigation/navbar";
import Footer from "../../components/footer/footer";

import { team } from "../../team";

import FranceFintech from "../../assets/FranceFintech_logo.png";
import StationF from "../../assets/station-f-logo.png";
import Norrsken from "../../assets/norrsken-logo.png";
import logo from "../../assets/leja.png";
import lejaplaystore from "../../assets/google-play-badge.png";
import Vector1 from "../../assets/vector1.png";
import Vector3 from "../../assets/vector3.png";
import Vector4 from "../../assets/vector4.png";
import Circle from "../../assets/circle-vector.png";

import { MouseContext } from "../../context/mouse-context";

import "./about.scss";

const Aboutpage = () => {
  // context api
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  return (
    <div className="homepage">
      <div className="home-banner">
        <TransparentNavbar />
        <div
          className="banner-text_container"
          // data-aos="fade-up"
          // data-aos-duration="3000"
        >
          <p className="banner-text">
            Unlocking growth for micro businesses in Africa
          </p>
        </div>
      </div>
      <div className="main-content__content">
        <div className="title-container">
          <p
            className="title"
            //data-aos="fade-down"
            //data-aos-duration="3000"
          >
            We use technology to give African micro-businesses the means to
            thrive.
          </p>
        </div>
        <div className="problem-solution__container">
          <div
            className="problem-container"
            // data-aos="fade-right"
            // data-aos-duration="3000"
            // data-aos-delay="1000"
          >
            <h2 className="heading problem">The Problem</h2>
            <p className="description">
              While entrepreneurship is a major driver of the African economy,
              African micro-entrepreneurs are still largely excluded from any
              formal protection or funding. This is due to a lack of
              consolidated data that substantiates their value, economic
              contributions, and growth potential.
            </p>
          </div>
          <div
            className="solution-container"
            // data-aos="fade-left"
            // data-aos-duration="3000"
            // data-aos-delay="1000"
          >
            <h2 className="heading solution">The Solution</h2>
            <p className="description">
              We provide simple and easy-to-use digital tools truly tailored to
              the needs of African microbusinesses thereby helping them
              transition to digital operations and gain access to affordable and
              valuable funding.
            </p>
          </div>
        </div>
        <div
          className="btn-container"
          // data-aos="zoom-in"
          // data-aos-duration="3000"
          // data-aos-delay="2000"
        >
          <button className="filled">
            <Link
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
              className="link"
              to="/"
            >
              Our Product
            </Link>
          </button>
        </div>
        <div className="trusted-container">
          <p
            className="description"
            // data-aos="fade-down"
            // data-aos-duration="3000"
            // data-aos-delay="1000"
          >
            Trusted by +50,000 microbusinesses in Kenya.
          </p>
        </div>
        <div className="vision-container">
          <img className="vector-1" src={Vector1} alt="vector" />
          <div className="heading-container">
            <h2
              className="heading"
              // data-aos="zoom-in"
              // data-aos-duration="3000"
              // data-aos-delay="1000"
            >
              Our vision
            </h2>
            <h2
              className="sub-heading"
              // data-aos="zoom-in"
              // data-aos-duration="3000"
              // data-aos-delay="1500"
            >
              A finance accessible and affordable for everyone.
            </h2>
          </div>
          <div className="vision-text">
            <p
              className="text"
              // data-aos="fade-down"
              // data-aos-duration="3000"
              // data-aos-delay="2000"
            >
              Asilimia believes in the high potential of the African continent
              and its new generation of entrepreneurs. However, the lack of
              access to funding leaves their potential untapped and their goals
              unmet.
            </p>
            <p
              className="text"
              // data-aos="fade-down"
              // data-aos-duration="3000"
              // data-aos-delay="2500"
            >
              The use of technological solutions would help them streamline
              their operations and ease the process of improving their financial
              inclusion.
            </p>
          </div>
        </div>
        <div className="mission-container">
          <img className="circle-vector" src={Circle} alt="vector" />
          <img className="vector-3" src={Vector3} alt="vector" />
          <div className="heading-container">
            <h2
              className="heading"
              // data-aos="zoom-in"
              // data-aos-duration="3000"
              // data-aos-delay="1000"
            >
              Our mission
            </h2>
          </div>
          <div className="mission-text">
            <p
              className="text"
              // data-aos="fade-down"
              // data-aos-duration="3000"
              // data-aos-delay="2000"
            >
              We aim to eradicate the current <span>$500B massive </span>
              credit gap and that is holding back about 150 million
              micro-businesses from growing.
            </p>
            <p
              className="text"
              // data-aos="fade-down"
              // data-aos-duration="3000"
              // data-aos-delay="2500"
            >
              By consolidating data from microbusinesses, we have built a strong
              and verifiable financial track record for financial institutions.
              They can now assess these businesses not only on their willingness
              to pay but their real ability to pay.
            </p>
          </div>
        </div>
        <div className="principles-container">
          <div className="vector-container">
            <img src={Vector4} alt="vector" />
          </div>
          <div className="heading-container">
            <h2
              className="heading"
              // data-aos="zoom-in"
              // data-aos-duration="3000"
              // data-aos-delay="1000"
            >
              Our principles
            </h2>
          </div>
          <div className="principles">
            <div
              className="principle"
              // data-aos="fade-down"
              // data-aos-duration="3000"
              // data-aos-delay="1500"
            >
              <h2 className="title">Ease</h2>
              <p className="description">
                Always building simple solutions that can be adapted by
                entrepreneurs from all walks of life.
              </p>
            </div>
            <div
              className="principle"
              // data-aos="fade-down"
              // data-aos-duration="3000"
              // data-aos-delay="2000"
            >
              <h2 className="title">Freedom</h2>
              <p className="description">
                Providing our users with flexibility through our products that
                can be used anywhere, anytime, on any mobile device.
              </p>
            </div>
            <div
              className="principle"
              // data-aos="fade-down"
              // data-aos-duration="3000"
              // data-aos-delay="2500"
            >
              <h2 className="title">Value</h2>
              <p className="description">
                Through solutions that save time, money, and ease access to
                funding.
              </p>
            </div>
          </div>
        </div>
        <div className="team-container">
          <div className="text-container">
            <h2
              className="title"
              // data-aos="zoom-in"
              // data-aos-duration="3000"
              // data-aos-delay="1000"
            >
              Our Team
            </h2>
            <div className="description-container">
              <p
                className="description"
                // data-aos="zoom-in"
                // data-aos-duration="3000"
                // data-aos-delay="1500"
              >
                We are a passionate team dedicated to helping micro-businesses
                in Africa achieve their full potential. We harness research and
                our professional experience, in tech and finance, to create
                practical, user-friendly digital solutions that promote
                financial inclusion.
              </p>
            </div>
          </div>
          <div className="team">
            {team.map((member) => (
              <div
                className="team-member"
                // data-aos="fade-down"
                // data-aos-duration="3000"
                // data-aos-delay="1000"
              >
                <div className="img-container">
                  <img src={member.image} alt="profile" />
                </div>
                <div className="member-details">
                  <p className="name">{member.name}</p>
                  <p className="role">{member.role}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="backed-by__container">
          <h2
            className="heading"
            // data-aos="zoom-in-up"
            // data-aos-duration="3000"
            // data-aos-delay="3000"
          >
            Backed by
          </h2>
          <div className="companies-container">
            <div
              className="img-container"
              // data-aos="flip-left"
              // data-aos-duration="3000"
              // data-aos-delay="1000"
            >
              <img src={Norrsken} alt="company" />
            </div>
            <div
              className="img-container"
              // data-aos="flip-left"
              // data-aos-duration="3000"
              // data-aos-delay="2000"
            >
              <img src={StationF} alt="company" />
            </div>
            <div
              className="img-container"
              // data-aos="flip-left"
              // data-aos-duration="3000"
              // data-aos-delay="3000"
            >
              <img src={FranceFintech} alt="company" />
            </div>
          </div>
        </div>
        <div
          className="simplest-container"
          // data-aos="zoom-in"
          // data-aos-duration="3000"
        >
          <div
            className="img-container"
            // data-aos="fade-left"
            // data-aos-duration="3000"
            // data-aos-delay="1000"
          >
            <img src={logo} alt="logo" />
          </div>
          <div className="simplest-content">
            <h2
              className="title"
              // data-aos="fade-left"
              // data-aos-duration="3000"
              // data-aos-delay="2000"
            >
              The simplest way to manage your business.
            </h2>
            <p
              className="description"
              // data-aos="fade-left"
              // data-aos-duration="3000"
              // data-aos-delay="3000"
            >
              Leja is a digital cash-book application. <br /> It enables
              micro-businesses to record their sales, manage their expenses
              while building a financial track record.
            </p>
          </div>
          <div
            className="btn-container"
            // data-aos="zoom-in"
            // data-aos-duration="3000"
            // data-aos-delay="2000"
          >
            <button
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
              className="filled"
            >
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.okoleainternational.okoleamobile&hl=en&gl=US"
              >
                <img src={lejaplaystore} alt="download button" />
              </a>
              {/* <a
                href="https://play.google.com/store/apps/details?id=com.leja"
                className="link"
              >
                Get the app
              </a> */}
            </button>
            <button
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
              className="filled"
            >
              <Link to="/" className="link">
                See more information
              </Link>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutpage;
