import { useEffect, useState } from "react";

// to get the mouse position
const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  useEffect(() => {
    const mouseMoveHandler = (e) => {
      const { clientX, clientY } = e;
      setMousePosition({ x: clientX, y: clientY });
    };

    document.addEventListener("mousemove", mouseMoveHandler); // listen to a mouse move event

    //for clean up
    return () => {
      document.addEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  return mousePosition; //return the new mouse coordinates
};

export default useMousePosition;
