import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Stack } from "@mui/material";

import { Navbar } from "../../components/navigation/navbar";
import Footer from "../../components/footer/footer";

const category = {
  justifyContent: "center",
  fontWeight: "bold",
  fontSize: "25px",
  marginTop: "1.5rem",
  marginBottom: "1rem",
};
export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Navbar />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            marginTop: "6rem",
            fontWeight: "700",
            justifyContent: "center",
          }}
        >
          Change Agents Frequently Asked Questions
        </Typography>
      </Stack>
      <Typography sx={category}>About Change Agents</Typography>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            What is Change Agents App?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Change Agents is an app that provides instant loans to Kenyans
            through their mobile phones. Clients' loan limits are reviewed and
            increased every time they repay their loans on time. Change agents
            app now allows you to access and manage all your business finances
            anytime and anywhere with access to mobile payments at a reduced
            cost.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            In which countries do Change Agents operate?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            At the moment, Change Agents operates only in Kenya.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Which networks do Change Agents support?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In Kenya, we support Safaricom. We hope to expand our coverage in
            the near future.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do I get the latest versions on the App?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Follow either of the following steps:
            <ol>
              <li>
                Click <a href="https://bit.ly/3w8JEuP">here</a> and select
                Update, or{" "}
              </li>
              <li>
                {" "}
                go to Google Play, search for <b>Change Agents App</b> then
                click <b>"Update”</b>
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography sx={category}>Our services</Typography>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966", fontWeight: "bold" }}>
            Send money
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This feature provides users with a platform to send money to other
            MPESA users at an agreed transaction fee. Users can track their
            transaction history from the app. You will be required to grant
            access to your contact list <b>SHOULD</b> you choose to select a
            contact from your contact list within the application.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966", fontWeight: "bold" }}>
            Buy Airtime
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This feature allows users to recharge airtime to their or other
            mobile network users using local Based mobile service providers;
            Safaricom, Airtel and Telkom sim cards. Users can track their
            transaction history from the application. You will be required to
            grant access to your contact list - you choose to select a contact
            from your contact list within the application.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966", fontWeight: "bold" }}>
            Loans
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Change Agents provides users with access to loans that are of low
            interest rates. The types of loans available on Change Agents
            include <b>Airtime loans</b> and <b>Instant mobile loans</b> (amount
            is deposited directly to the user's registered MPESA number). For
            the purposes of this clause, Services would include any other future
            services the Company provides/proposes to provide.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography sx={category}>My Account</Typography>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do I check my personal details?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To check your personal details, tap on the icon on the top left
            corner of your screen that takes you to “My Account”. Then tap
            “Profile” to check your personal information.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How to reset my password?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To reset Password, click on forget password on the login screen.
            Thereafter, you will be required to enter your <b>email address</b>{" "}
            registered with Change Agents through which you will get a link to
            reset your Login password.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Can I change my ID number after receiving a loan?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            At Change Agents, we conform to the highest standards of data
            integrity. This means that you cannot change information that was
            previously used to process a successful loan application. We
            apologize for any inconveniences caused.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Can I delete my account ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes. However, you should note when you delete your account you can
            no longer Access your Change agents application.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography sx={category}>Loan Offer</Typography>
      <Accordion
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do I apply for a loan?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Applying for a loan is easy! After you have downloaded the Change
            Agents App, all you need to do is sign up, navigate to the “Loans”
            option at the bottom of the Home screen, and fill in your personal
            details, phone number and ID number. Then you can view available
            loan limit, select an option, and tap “Apply”.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel13"}
        onChange={handleChange("panel13")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do you determine my loan limit?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>
                The loan limit you see depends on a number of factors, including
                your repayment history with Change Agents App. If you repay each
                installment by its due date, you may unlock access to larger
                loans over time.
              </li>
            </ul>
            <p>
              <b>
                Note that the increase may not always be immediate, and that
                repaying your loan late may result in smaller loan offers.
              </b>
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel14"}
        onChange={handleChange("panel14")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Can I Apply for a higher amount than shown?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Unfortunately, you won't be able to access a loan of a larger size.
            The fastest way to increase the amount you are eligible for is to
            build credit by making each repayment as scheduled.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel15"}
        onChange={handleChange("panel15")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do I repay my loan?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To repay your Change Agents loan, click on the REPAY LOAN BUTTON on
            the app or you repay through Mpesa by sending the loan amount to
            Paybill number 245316. Your mobile number is your account number or
            by dialing a USSD code *842#.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography sx={category}>Loan Eligibility Criteria</Typography>

      <Accordion
        expanded={expanded === "panel16"}
        onChange={handleChange("panel16")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            What is required to receive a Change agent loan?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              Requirements are simple - all you need to apply is your gmail
              account, National ID and mobile money account.
            </p>
            <p>
              We will also request access to the data on your phone in order to
              determine your eligibility.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel17"}
        onChange={handleChange("panel17")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Why was I rejected for a loan?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you don’t get accepted when you apply, don't worry! Sometimes it
            may take several attempts to qualify for a loan. We encourage you to
            continue Using Change agents application in sending money and buying
            airtime and to reapply after the period stipulated.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography sx={category}>Late repayment And CRB</Typography>
      <Accordion
        expanded={expanded === "panel18"}
        onChange={handleChange("panel18")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            What will happen when I miss out on my loan repayment?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Paying each installment by its due date allows you to access larger
            loan sizes. Late repayments will affect your ability to get
            subsequent or larger loans. If you are very late on your payments,
            we report to the Credit Reference Bureau (CRB). Blacklisting by the
            CRB may affect your ability to borrow from other lenders.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel19"}
        onChange={handleChange("panel19")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Can I request to pay at a later date?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Unfortunately, the Change Agents App cannot alter your repayment
            schedule after you've received a loan. If you are unable to repay
            the full amount due, we advise you to start making partial
            repayments as soon as possible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel20"}
        onChange={handleChange("panel20")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", color: "#009966", flexShrink: 0 }}>
            What should I do when I clear my defaulted Loan?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We will update and forward your details to the CRB for clearance.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Footer />
    </Container>
  );
}
