import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';

import MouseContextProvider from './context/mouse-context';

ReactDOM.render(
    <BrowserRouter>
        <MouseContextProvider>
            <App />
        </MouseContextProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
