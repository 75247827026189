import React, { useContext } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  createTheme,
} from "@mui/material";
import {
  StyledFooterLink,
  StyledFooterLinkNoBold,
} from "../utils/styledComponents";

import { MouseContext } from "../../context/mouse-context";
// import './footer.scss';
import lejaplaystore from "./../../assets/google-play-badge.webp";

const lightTheme = createTheme({ palette: { mode: "light" } });

export default function Footer() {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  return (
    <Container
      disableGutters
      maxWidth="fluid"
      fontFamily='font-family: "Rubik", sans-serif'
    >
      <Box
        paddingX={{ xs: "1rem", lg: ".5rem" }}
        paddingTop={{ xs: "1rem", lg: "3rem" }}
        paddingBottom={{ xs: "1rem", lg: "0.5rem" }}
        marginLeft="1rem"
      >
        <Grid
          container
          columns={{ xs: 3, md: 6, lg: 15, xl: 15 }}
          justify="center"
        >
          <Grid item xs={3} textAlign={{ md: "left" }}>
            <Box>
              <Typography
                variant={"h5"}
                fontWeight="bold"
                marginBottom="0.35rem"
              >
                France
              </Typography>
              <StyledFooterLinkNoBold
                onMouseEnter={() => cursorChangeHandler("hovered")}
                onMouseLeave={() => cursorChangeHandler("")}
                rel="noopener"
                href="https://goo.gl/maps/hF4WuLvVfVc3SzLH7"
                target={"_blank"}
              >
                <Box>5 rue Frédéric Phélypeaux, 78640</Box>
                <Box marginBottom="0.35rem">Villiers-Saint-Frédéric France</Box>
              </StyledFooterLinkNoBold>
            </Box>
            <Box>
              <Typography variant={"h5"} fontWeight="bold">
                Kenya
              </Typography>
              <StyledFooterLinkNoBold
                onMouseEnter={() => cursorChangeHandler("hovered")}
                onMouseLeave={() => cursorChangeHandler("")}
                rel="noopener"
                href="https://goo.gl/maps/F4s7XQMRnf6MPakw5"
                target={"_blank"}
              >
                <Box>Address Galana Road, </Box>
                <Box>711 Kilimani, Nairobi</Box>
              </StyledFooterLinkNoBold>
            </Box>
            <Box>
              <Typography
                variant={"h5"}
                fontWeight="bold"
                marginTop="0.35rem"
              >
                United Kingdom
              </Typography>
              <StyledFooterLinkNoBold
                onMouseEnter={() => cursorChangeHandler("hovered")}
                onMouseLeave={() => cursorChangeHandler("")}
                rel="noopener"
                href="#"
                target={"_blank"}
              >
                <Box>20 St Andrews St,</Box>
                <Box marginBottom="0.35rem">Holborn Circus, EC4A 3AG</Box>
              </StyledFooterLinkNoBold>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <Typography
                variant={"h5"}
                fontWeight="bold"
                marginBottom="0.35rem"
              >
                Quick Links
              </Typography>
              <Box>
                <Typography
                  // disableGutters
                  onMouseEnter={() => cursorChangeHandler("hovered")}
                  onMouseLeave={() => cursorChangeHandler("")}
                >
                  <StyledFooterLink
                    variant="body2"
                    rel="noopener"
                    href="/about"
                  >
                    About Us
                  </StyledFooterLink>
                </Typography>
                <Typography
                  // disableGutters
                  onMouseEnter={() => cursorChangeHandler("hovered")}
                  onMouseLeave={() => cursorChangeHandler("")}
                >
                  <StyledFooterLink variant="body2" href="/faqs">
                    FAQ
                  </StyledFooterLink>
                </Typography>
                <Typography
                  // disableGutters
                  onMouseEnter={() => cursorChangeHandler("hovered")}
                  onMouseLeave={() => cursorChangeHandler("")}
                >
                  <StyledFooterLink
                    variant="body2"
                    href="/leja/privacy-policy"
                    target={"_blank"}
                  >
                    Privacy Policy
                  </StyledFooterLink>
                </Typography>
                <Typography
                  // disableGutters
                  onMouseEnter={() => cursorChangeHandler("hovered")}
                  onMouseLeave={() => cursorChangeHandler("")}
                >
                  <StyledFooterLink
                    variant="body2"
                    href="/leja/terms-and-conditions"
                    target={"_blank"}
                  >
                    Terms and Conditions
                  </StyledFooterLink>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} textAlign={{ md: "left" }}>
            <Box>
              <Typography
                variant={"h5"}
                fontWeight="bold"
                marginBottom="0.35rem"
              >
                Contact Us
              </Typography>
              <Box>
                <Typography
                  onMouseEnter={() => cursorChangeHandler("hovered")}
                  onMouseLeave={() => cursorChangeHandler("")}
                >
                  <StyledFooterLink variant="body2" href="tel: +254111052280">
                    +254 111 052280
                  </StyledFooterLink>
                </Typography>
                <Typography
                  onMouseEnter={() => cursorChangeHandler("hovered")}
                  onMouseLeave={() => cursorChangeHandler("")}
                >
                  <StyledFooterLink
                    variant="body2"
                    href="mailto: info@asilimia.co.ke"
                  >
                    info@asilimia.co.ke
                  </StyledFooterLink>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} textAlign={{ md: "left" }}>
            <Box>
              <Typography
                variant={"h5"}
                fontWeight="bold"
                marginBottom="0.35rem"
              >
                Social Links
              </Typography>
              <Typography
                onMouseEnter={() => cursorChangeHandler("hovered")}
                onMouseLeave={() => cursorChangeHandler("")}
              >
                <Link
                  variant="body2"
                  color="inherit"
                  target="_blank"
                  href="#"
                  marginRight="0.8rem"
                >
                  <FacebookIcon
                    fontSize="large"
                    paddingX={{ sm: "1rem" }}
                    sx={{
                      color: "#000000",
                      "&:hover,&:focus": {
                        color: "#009966",
                      },
                    }}
                  />
                </Link>
                <Link
                  variant="body2"
                  color="inherit"
                  target="_blank"
                  href="https://www.linkedin.com/company/asilimia/"
                  marginRight="0.8rem"
                >
                  <Link variant="body2" color="inherit" edInIcon />
                  <LinkedInIcon
                    fontSize="large"
                    paddingX={{ sm: "1rem" }}
                    sx={{
                      color: "#000000",
                      "&:hover,&:focus": {
                        color: "#009966",
                      },
                    }}
                  />
                </Link>
                <Link
                  variant="body2"
                  color="inherit"
                  target="_blank"
                  href="https://www.youtube.com/channel/UC8LKCBrdIPGWSYTiMBTByhw"
                  marginRight="0.8rem"
                >
                  <YouTubeIcon
                    fontSize="large"
                    paddingX={{ sm: "1rem" }}
                    sx={{
                      color: "#000000",
                      "&:hover,&:focus": {
                        color: "#009966",
                      },
                    }}
                  />
                </Link>
                <Link
                  variant="body2"
                  color="inherit"
                  target="_blank"
                  href="#"
                  marginRight="0.8rem"
                >
                  <TwitterIcon
                    fontSize="large"
                    paddingX={{ sm: "1rem" }}
                    sx={{
                      color: "#000000",
                      "&:hover,&:focus": {
                        color: "#009966",
                      },
                    }}
                  />
                </Link>
                <Link variant="body2" color="inherit" target="_blank" href="#">
                  <InstagramIcon
                    fontSize="large"
                    paddingX={{ sm: "1rem" }}
                    sx={{
                      color: "#000000",
                      "&:hover,&:focus": {
                        color: "#009966",
                      },
                    }}
                  />
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} textAlign={{ xs: "left" }}>
            <Typography variant="h5" fontWeight="bold" marginLeft="0.5rem">
              Download App
            </Typography>
            <Link
              variant="body2"
              color="inherit"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.okoleainternational.okoleamobile&hl=en&gl=US"
            >
              <img src={lejaplaystore} alt="download button" />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box
        textAlign={{ xs: "left", md: "center" }}
        paddingX={{ xs: "1rem", lg: 0 }}
      >
        <Typography>&copy; Asilimia, Inc. 2022</Typography>
      </Box>
    </Container>
  );
}
