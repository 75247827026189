import { Routes, Route } from "react-router-dom";
import AOS from "aos";
import Lejapage from "./pages/leja/leja";
import Aboutpage from "./pages/about/about";
import PrivacyPolicy from "./pages/privacypolicy/privacyPolicy";
import TermsAndConditions from "./pages/terms-conditions/TermsandConditions";
import FortuneLejaTermsAndConditions from "./pages/fortuneLejaAccounts/TermsAndConditions";
import Faq from "./pages/faq/faq";
import OkoleaTermsAndConditions from "./pages/OkoleaTermsAndConditions/okoleaTermsandConditions";
import OkoleaPrivacyPolicy from "./pages/OkoleaPrivacy/OkoleaPrivacyPolicy";
import OkoleaFaq from "./pages/Okolea-Faq/OkoleaFaq";
import DotRing from "./components/cursor/cursor";
import "aos/dist/aos.css";
import "./App.css";

AOS.init({
  once: true,
  mirror: true,
});

function App() {
  return (
    <div className="App">
      <DotRing />
      <Routes>
        <Route path="/" element={<Lejapage />} />
        <Route path="/about" element={<Aboutpage />} />
        <Route path="/leja/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/faqs" element={<Faq />} />
        <Route
          path="/fortune-leja-accounts/terms-and-conditions"
          element={<FortuneLejaTermsAndConditions />}
        />
        <Route
          path="/change-agents/privacy-policy"
          element={<OkoleaPrivacyPolicy />}
        />
        <Route
          path="/leja/terms-and-conditions"
          element={<OkoleaTermsAndConditions />}
        />
        <Route path="/change-agents/faqs" element={<OkoleaFaq />} />
      </Routes>
    </div>
  );
}

export default App;
