/** @format */

import React from 'react';
import { Container, Stack, Grid, Typography, Box, Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Navbar } from '../../components/navigation/navbar';
import Footer from '../../components/footer/footer';

const root = {
	// charges-interest
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
};
const container = {
	// maxWidth: '780px',
	padding: '80px 24px',
	display: 'flex',
	flexDirection: 'column',
	minHeight: '98vh',
};
const paragraph = {
	// fontWeight: "390",
	// fontSize: "16px",
	// fontFamily: "Rubik",
	// color: "#000000",

	marginTop: '1rem',
	marginLeft: '1.5rem',
	marginBottom: '0.7rem',
};
const cover = {
	height: '450px',
	width: '100%',
	position: 'relative',
	margin: '0 auto',
	//   backgroundImage: `url(${banner})`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundAttachment: 'fixed',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};
const heading = {
	color: 'black',
	fontSize: '30px',
	paddingTop: '12px',
	fontWeight: 'bold',
};
const mainHeading = {
	color: 'black',
	fontSize: '20px',
	paddingTop: '20px',
	textAlign: 'center',
};
const subHeading = {
	color: 'black',
	fontSize: '25px',
	paddingTop: '10px',
	fontWeight: 'bold',
};

const Ulist = {
	listStyleType: 'disc',
	display: 'list-item',
	listStylePosition: 'outside',
};
const contactList = {
	marginTop: 1,
	padding: 3,
	textAlign: 'left',
	fontWeight: 'bold',
	width: '40%',
};

const table = {
	minWidth: 650,
};
const headerCell = {
	fontWeight: 'bold',
	fontSize: '18px',
	borderBottom: 'solid #000000 1px',
	backgroundColor: '#ececec',
};
const tableCell = {
	borderRight: 'solid #000000 1px',
};
const title = {
	textAlign: 'center',
	fontWeight: '800',
	color: '#000000',
	fontSize: '30px',
	fontFamily: 'Rubik',
	marginTop: '2rem',
};
const date = {
	textAlign: 'left',
	fontWeight: 'bold',
};

const OkoleaTermsAndConditions = (props) => {
	return (
		<Container>
			<Navbar />
			<Box
				variant='h4'
				gutterBottom
				sx={{
					marginTop: '6rem',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Box
					variant='h2'
					gutterBottom
					sx={title}>
					LEJA TERMS AND CONDITIONS
				</Box>
				<Box sx={date}>Last updated September 20, 2023</Box>
			</Box>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				mb={1}></Stack>
			<Box sx={container}>
				<Grid
					container
					spacing={2}>
					<Typography component='div'>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							1. Definitions of Terms
						</Box>
						<Box style={{ width: '100%', textAlign: 'justify' }}>
							<ol sx={Ulist}>
								<li>
									<span>
										{' '}
										<strong> “LEJA”</strong>
									</span>{' '}
									means Leja Limited, a Limited Liability company incorporated
									in accordance to Cap. 486 Laws of Kenya with its offices
									situated at Maple Court, Galana Road Kilimani and of P.O.BOX
									711, Kilimani. This may also include its Holding company,
									Affiliates and/or subsidiaries;
								</li>
								<li>
									<span>
										{' '}
										<strong> “Application/App”</strong>
									</span>{' '}
									means Leja Application available at{' '}
									<a
										href='https://play.google.com/store/apps/details?id=com.leja'
										target='_blank'
										rel='noreferrer'>
										Google Playstore.
									</a>{' '}
								</li>
								<li>
									<span>
										{' '}
										<strong> “Lender”</strong>
									</span>{' '}
									means any Money lending institutions who have partnered with
									LEJA with an intent to lend money to customers through the
									LEJA Platform;
								</li>
								<li>
									<span>
										<strong>"You" or "your"</strong>{' '}
									</span>{' '}
									means the Customer and includes the personal representatives,
									assigns and heirs of the Customer;
								</li>
								<li>
									<span>
										<strong>"“Customer”"</strong>{' '}
									</span>{' '}
									means a person whose name is registered in the App with valid
									identification details and a registered mobile phone number;
									The word{' '}
									<span>
										<strong>“Customer"</strong>{' '}
									</span>{' '}
									shall include the masculine and the feminine gender as well as
									juristic persons;
								</li>
								<li>
									<span>
										<strong>“LEJA Platform”</strong>
									</span>{' '}
									means LEJA Limited’s electronic lending and communication
									software enabling the Customer to communicate with LEJA for
									the purpose of accessing the Facility and Services;
								</li>
								<li>
									<span>
										<strong>“Account”</strong>
									</span>{' '}
									means your Loan account with LEJA;
								</li>
								<li>
									<span>
										<strong>“Credentials”</strong>
									</span>{' '}
									means your personal credentials used to access the Leja
									Platform, initiate requests and access the Services;
								</li>
								<li>
									<strong>“Service”</strong>
									means any Product offered on the Leja Platform or USSD Short
									code and shall include but not limited to;
									<ul>
										<li>
											<span>
												<strong>Digital Book Keeping</strong>
											</span>{' '}
											Leja provides users with a platform to digitally record
											their debit and credit transactions with their respective
											customers. The Users may upload information relating to
											transactions with their customers or relating to their
											businesses, including the sale of goods or services,
											costs, amounts paid and payable, and details of goods and
											services, on the Platform as shown below;
										</li>
										<li>
											<span>
												<strong>Send Money</strong>
											</span>{' '}
											This feature provides users with a platform to send money
											to other MPESA users at a zero transaction fee. Users can
											track their transaction history from the app.
										</li>
										<li>
											<span>
												<strong>Buy Airtime</strong>
											</span>{' '}
											This feature allows users to recharge airtime to their or
											other mobile network users using local Based mobile
											service providers; Safaricom, Airtel and Telkom sim cards.
											Users can track their transaction history from the app.
										</li>
										<li>
											<span>
												<strong>Borrow Loans </strong>
											</span>{' '}
											LEJA has partnered with Money lending institutions who
											through LEJA, provide individuals and microbusinesses with
											access to loans that are of low interest rates. The types
											of loans available on Leja include <strong>Airtime loans </strong> 
											and <span><strong>Business loans</strong></span>. Business loans are further classified into
											<strong> Instant mobile loans </strong> (amount is deposited directly to
											user’s registered MPESA number) and <strong> Business loans </strong> (allows
											users to pay for goods and services directly to their
											suppliers).
										</li>
										<li>
											<span>
												<strong>Wallet</strong>
											</span>{' '}
											– LEJA has partnered with money lending institutions who
											through LEJA, provide a wallet where customers can deposit
											money, perform transactions via the wallet including but
											not limited to: Transfers, withdrawals, payment of bills,
											access to loans etcetera.
										</li>
										<li>
											Services also include any other future services provided
											by LEJA and/or proposes to provide.
										</li>
									</ul>
								</li>
								<li>
									<span>
										<strong> “Facility” </strong>{' '}
									</span>{' '}
									means the Loan which the Customer requests from the LEJA
									Platform through their registered and recognised Mobile Phone
									Number;
								</li>
								<li>
									<span>
										<strong> “Processing Fee” </strong>{' '}
									</span>{' '}
									is the fee charged on loan disbursal and is a fixed percentage
									of the Loan Amount;
								</li>
								<li>
									<span>
										<strong> “Loan” </strong>{' '}
									</span>{' '}
									means a loan disbursed electronically by LEJA to a Customer
									M-PESA account, electronic wallet account (“E-Wallet”) or to
									any other available payment option selected by the Customer
									upon a Customer’s request.
								</li>
								<li>
									<span>
										<strong> “Repayment Date”</strong>{' '}
									</span>{' '}
									means the day that the Loan instalment shall be due for
									repayment;
								</li>
								<li>
									<span>
										<strong> “ Penalty ”</strong>{' '}
									</span>{' '}
									means a charge which will be a percentage on any outstanding
									amounts which have become due but remain unpaid on the
									Repayment Date;
								</li>
								<li>
									<span>
										<strong> “Request”</strong>{' '}
									</span>{' '}
									means a request or instruction received by LEJA from you or
									purportedly from you through your Mobile Network Operator and
									the Leja Platform and upon which LEJA is authorized to act;
								</li>
								<li>
									<span>
										<strong> “Loan Limit”</strong>{' '}
									</span>{' '}
									means the maximum pre-approved amount that a Customer can
									borrow on the App;
								</li>
								<li>
									<span>
										<strong> “Credit Reference Bureau” </strong>{' '}
									</span>{' '}
									means a credit reference bureau duly licensed and registered
									under the Credit Reference Bureau Regulations 2013 to inter
									alia, collect and facilitate the sharing of customer credit
									information;
								</li>
								<li>
									<span>
										<strong>“Mobile Money”</strong>{' '}
									</span>{' '}
									means the money transfer and payments service operated by the
									Mobile Money Providers in Kenya;
								</li>
								<li>
									<span>
										<strong>“Mobile Money Account"</strong>{' '}
									</span>{' '}
									means your mobile money store of value, being the record
									maintained by Mobile Money Providers in Kenya of the amount of
									E-Money from time to time held by you in the Mobile Money
									Provider’s System;
								</li>
								<li>
									<span>
										<strong>“Mobile Network Operator”</strong>{' '}
									</span>{' '}
									means a mobile network operator in Kenya registered with the
									Communications Authority of Kenya;
								</li>
								<li>
									<span>
										<strong>“Mobile Money Provider”</strong>{' '}
									</span>{' '}
									means a Mobile Network Operator that has been duly authorized
									by the Central Bank of Kenya under applicable law to offer
									Mobile Money Services in Kenya;
								</li>
								<li>
									<span>
										<strong>“Equipment”</strong>{' '}
									</span>{' '}
									means and shall include your mobile phone handset, SIM Card
									and/or other equipment which when used together enables you to
									access the Just Cash System and use the products and services
									availed to you by the Mobile Network Operators subject to such
									term and conditions agreed between you and the Mobile Network
									Operators;
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							2. Acceptance of the Terms and Conditions
						</Box>
						<Box
							fontWeight='fontWeightLight'
							m={1}>
							<ol>
								<li>
									Before applying for the Service you should carefully read and
									understand these Terms and Conditions which will govern the
									provision, use, operation and management of the Service.
								</li>
								<li>
									If you do not agree with these Terms and Conditions, please
									cancel the Registration by failing to click ACCEPT and do not
									confirm your National Identification number, Mobile Phone
									Number and Personal Details.
								</li>
								<li>
									You will be deemed to have read, understood and accepted these
									Terms and Conditions:-
									<ol>
										<li>By Clicking Accept;</li>
										<li>By continuing to use Leja services</li>
									</ol>
								</li>
								<li>
									By applying to access the Service with LEJA you agree to
									comply with and be bound by these Terms and Conditions for the
									time being and from time to time in force governing the
									operation of the Service and you affirm that these Terms and
									Conditions herein are without prejudice to any right that LEJA
									may have with respect to the Service in law or otherwise.
								</li>
								<li>
									These Terms and Conditions may be amended or varied by LEJA
									from time to time and the continued use, access, enjoyment of
									the Service constitutes your agreement to be bound by these
									terms and conditions or any such amendment, addition, deletion
									or variation.{' '}
								</li>
								<li>
									You acknowledge and accept that LEJA offers the Service only
									by electronic means via the Leja Platform.
								</li>
								<li>
									By using the Leja Platform or any of the Services, you consent
									to LEJA collecting and using technical information about the
									Equipment and related software, hardware and peripherals for
									Services that are internet-based or wireless to improve our
									products and to provide any Services to you. If you use these
									Services, you consent to us and our affiliates' and licensees'
									transmission, collection, retention, maintenance, processing
									and use of your data to determine our credit scoring services
									or to improve our Services and/or your experience while using
									the Leja Platform.
								</li>
							</ol>
						</Box>
						<Box sx={heading}>3. Account opening</Box>
						<Box
							sx={paragraph}
							m={1}>
							<ol>
								<li>
									In order to open an account with LEJA, you must have achieved
									the age of the majority being at least Eighteen (18) years old
									with legal capacity to contract, and a registered and active
									Mobile Subscriber with a mobile service provider with which
									the Company has an agreement with for the provision of this
									service.
								</li>
								<li>
									To avail the Services, a Customer would be required to create
									an account on the Platform using their personal information as
									described in clause 4;
								</li>

								<li>
									LEJA may require permissions to use your email address while
									opening an account.
								</li>
								<li>
									You may open an Account solely by way of an electronic
									application made by you using your Equipment via the LEJA
									Platform on the Registration or Sign Up screen.
								</li>
								<li>
									LEJA will require you to click on the “Accept” button on the
									Registration screen requesting you to confirm that you have
									read, understood and agreed to abide with these Terms and
									Conditions.
								</li>
								<li>
									You hereby further acknowledge and authorize the Company to
									verify your Personal Information as received from the
									Registration Process.
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							4. Personal Information
						</Box>
						<Box>
							<ol>
								<li>
									You hereby agree and authorise LEJA to verify information
									provided by you to LEJA against the information held by the
									Mobile Money Providers or any other Personal information
									database including but not limited to the Integrated
									Population Registration System and Anti-Money Laundering
									database in relation to your Mobile Money Account pursuant to
									the agreement between you and the relevant Mobile Money
									Provider for the provision of its products and services and
									the Mobile Money Service.
								</li>
								<li>
									The information that LEJA may verify against the information
									held by the Mobile Money Providers or any other Personal
									information database including but not limited to the
									Integrated Population Registration System and Anti-Money
									Laundering database in relation to: your phone number, name,
									date of birth, Identification Number (“ID”) or Passport Number
									and any other such other information that will enable LEJA to
									identify you and comply with the regulatory “Know Your
									Customer” requirements (Collectively referred to as “Personal
									Information”).{' '}
								</li>
								<li>
									You hereby agree and authorise LEJA to verify information
									including, but not limited to, data relating to your phone
									(including, without limitation, your phone’s history) from
									your Equipment, from any SMS sent to you by the Mobile Money
									Providers and any financial services providers relating to
									your use of the Mobile Money Service and such other
									information as LEJA shall require for purposes of providing
									you the Services (Collectively referred to as the “Relevant
									Information”).
								</li>
								<li>
									You hereby consent to LEJA verifying the Personal Information
									and the Relevant Information with the Mobile Money Providers
									or any other Personal information database and using of the
									Personal Information and the Relevant Information to the
									extent necessary in the opinion of LEJA to provide you with
									the Services.
								</li>
								<li>
									You hereby agree and authorise LEJA to obtain and procure your
									Personal Information and Relevant Information from your
									respective Mobile Money Provider or any other Personal
									information database and you further agree and consent to the
									disclosure and provision of such Personal Information by the
									Mobile Money Provider and further to indemnify and hold LEJA
									and the Mobile Money Provider harmless with respect to any
									claims, losses, liabilities and expenses (including legal fees
									and expenses) that may arise as a result of the disclosure and
									reliance on such Personal Information and/or Relevant
									Information.{' '}
								</li>
								<li>
									You hereby authorise LEJA to access your information and use
									it in the manner prescribed in LEJA’s
									<a
										href='https://www.asilimia.co.ke/leja/privacy-policy'
										target='_blank'
										rel='noreferrer'>
										Updated privacy policy.
									</a>{' '}
								</li>
								<li>
									LEJA reserves the right to request for further information
									from you pertaining to your request for a Loan, Service or to
									access the Leja Platform at any time. Failure to provide such
									information within the time required by LEJA may result in
									LEJA declining to accept your request.
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							5. Customer’s Responsibilities
						</Box>
						<Box>
							<ol>
								<li>
									You shall at your own expense provide and maintain in safe and
									efficient operating order your Equipment necessary for the
									purpose of accessing the Leja Platform and the Services
									provided therein.
								</li>
								<li>
									You shall be responsible for ensuring the proper performance
									of your Equipment. LEJA shall neither be responsible for any
									errors or failures caused by any malfunction of your
									Equipment, and nor any computer virus or related problems that
									may be associated with the use of the Leja Platform, the
									Services and the Equipment.
								</li>
								<li>
									You shall be responsible for charges due to any service
									provider providing you with connection to the Leja Platform
									and LEJA shall not be responsible for losses or delays caused
									by any such service provider.
								</li>
								<li>
									You shall follow all instructions, procedures and terms
									contained in this Agreement and any document provided by LEJA
									concerning the use of the System and the Services.
								</li>
								<li>
									You hereby agree and acknowledge that you shall be solely
									responsible for the safekeeping and proper use of your
									Equipment and for keeping your Credentials secret and secure.
									You shall ensure that your Credentials do not become known or
									come into possession of any unauthorised person. LEJA shall
									not be liable for any disclosure of your Credentials to any
									third party and you hereby agree to indemnify and hold LEJA
									harmless from any losses resulting from any disclosure of your
									Credentials.
								</li>
								<li>
									You shall take all reasonable precautions to detect any
									unauthorised use and access of the System and the Services
									undertaken using your Equipment. To that end, you shall ensure
									that all communications from LEJA are examined and checked by
									you or on your behalf as soon as practicable after receipt by
									you in such a way that any unauthorised use of and access to
									the Leja Platform will be detected. You shall immediately
									inform LEJA in the event that:
									<ul>
										<li>
											You have reason to believe that your Credentials are or
											may be known to any person not authorised to know the same
											and/or have been compromised; and/or
										</li>
										<li>
											You have reason to believe that unauthorised access and
											use of the Leja Platform and the Services has or may have
											occurred or could occur and a transaction may have been
											fraudulently input or compromised.
										</li>
									</ul>
								</li>
								<li>
									You shall at all times follow the security procedures notified
									to you by LEJA from time to time or such other procedures as
									may be applicable to the Services from time to time. You
									acknowledge that any failure on your part to follow the
									recommended security procedures may result in a breach of your
									Personal Information, Relevant Information and such other
									information held by LEJA in connection to the Services and
									Requests made by you to LEJA in confidentiality. In
									particular, you shall ensure that the Services are not used or
									Requests are not issued or the relevant functions are not
									performed by anyone other than you or a person authorised by
									you to do so.
								</li>
								<li>
									You shall not at any time operate or use the Services in any
									manner that may be prejudicial to LEJA.
								</li>
								<li>
									You agree to indemnify LEJA against any losses incurred as a
									result of your failure to comply with the above
									responsibilities
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							6. Operations
						</Box>
						<Box>
							<ol>
								<li>
									You acknowledge that LEJA shall appraise your Facility request
									according to LEJA’s loan appraisal and vetting processes and
									that LEJA reserves the right, at its sole discretion, and
									without assigning any reason to approve or decline your
									application for a Facility.{' '}
								</li>
								<li>
									Subject to approval of your Facility request, LEJA shall lend
									and disburse to you an amount to be determined by LEJA in its
									sole discretion subject to a minimum amount of{' '}
									<strong>
										{' '}
										Kenya Shillings One Thousand (KES. 1,000) only
									</strong>{' '}
									and a maximum amount of{' '}
									<strong>
										Kenya Shillings Twenty Thousand (KES. 20,000) only
									</strong>{' '}
									which maximum shall be based on the Customer’s advised Loan
									Limit.{' '}
								</li>
								<li>
									The proceeds of the Loan shall be credited into either your
									M-PESA Account, E-Wallet or any other option availed by LEJA
									which you select, subject to any deductions on account of
									applicable Processing Fee and or Transaction Fees.
								</li>
								<li>
									In consideration of LEJA granting you the Loan, you shall pay
									an Processing Fee for the provision of the Service being{' '}
									<strong>Fifteen Percent (15%)</strong> which is exclusive of
									Excise Duty or at such other rate that LEJA shall determine
									and effect for the provision of the Services to You from time
									to time.
								</li>
								<li>
									You shall make payments due from you to LEJA in respect of the
									Loan on the Repayment Date using the LEJA APP unless otherwise
									agreed and advised by LEJA in its discretion.{' '}
								</li>
								<li>
									If you do not pay the Loan on the Repayment Date LEJA shall
									automatically charge a Late Penalty Charge without notice.
								</li>
								<li>
									You shall pay to LEJA any charges, fees, expenses, taxes,
									duties, impositions and expenses incurred by LEJA in complying
									with your Facility request.
								</li>
								<li>
									You hereby expressly give consent to LEJA to recover all
									monies due that you will not have settled on the Repayment
									Date through reminders via Calls and/or SMS or cause the
									Lenders who have partnered with Leja to list you as a
									defaulter with the Credit Reference Bureau.
								</li>
								<li>
									LEJA reserves the right to vary the terms of the Loan
									including the Processing Fee and Transaction Fees payable
									thereon from time to time.{' '}
								</li>
								<li>
									Any addition, alteration, modification or deletion to these
									Terms and Conditions which may be made from time to time by
									LEJA and of which notice may be given to you by way of Short
									Messaging Service or such other method that LEJA shall adopt
									and use to communicate with you shall be binding upon you as
									fully as if the same were contained in these Terms and
									conditions.
								</li>
								<li>
									You hereby undertake to notify LEJA immediately of any
									situation, circumstance or event, which materially changes the
									provision, use, operation and management of the Loan, Services
									or your access to the Leja Platform.{' '}
								</li>
								<li>
									If LEJA holds any money or you have a credit balance with LEJA
									at any time as a result of overpayment of your Loan, you may
									issue a Request to LEJA for payment of such credit balance and
									LEJA will return any such balance to you, less any applicable
									fees, provided that such amount falls above the minimum
									transfer amounts specified by the relevant Mobile Money
									Provider.
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							7. Credit Reference Bureaus
						</Box>
						<Box>
							<ol>
								<li>
									You hereby expressly authorise LEJA and its Lending Partners
									to solicit, query, search, conduct, carry out and perform
									credit checks with, and/or obtain the Customer’s credit
									information, or such other information, from any or all
									licensed Credit Reference Bureaus, with respect to this
									Agreement, and/or LEJA maintaining a Loan for you.
								</li>
								<li>
									You hereby expressly consent, allow and authorise LEJA and its
									Lending Partners to disclose, advise, exchange and communicate
									the details pertaining to your Loan to Credit Reference
									Bureaus (CRBs) and other Credit Providers.
								</li>
								<li>
									You authorise LEJA and its Lending Partners to obtain any
									information that it may require relating to the Loan from
									credit providers, and from any other source to which LEJA may
									apply, each source being hereby authorised by you to provide
									LEJA with such information.{' '}
								</li>
								<li>
									You hereby authorise LEJA’s lending partners to list you with
									the Credit Reference Bureau in case of default and/or
									non-performing loan.
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							8. Exclusion of Liability
						</Box>
						<Box>
							<ol>
								<li>
									LEJA will endeavour to do its utmost to ensure that there are
									no delays or failures in offering the Loan. However, LEJA
									shall not be liable for any loss suffered by you due to, as a
									result of, or in connection with failures that may arise as a
									result of inefficiencies caused by the Mobile Network
									Operators or which are occasioned by strikes, riots or civil
									unrest, breakages, fire, unforeseen commercial delays, acts of
									God or permission requirements or licences that are required
									from Mobile Network Operators.
								</li>
								<li>
									LEJA shall not be liable for any losses incurred by you as a
									result of another person accessing your equipment whether by
									consent, theft or fraud or where you have been scammed by
									allowing other persons access to your equipment;
								</li>
								<li>
									You hereby expressly consent to waive any claim against LEJA
									for delays or failures to offer the Loan, Service or respond
									to any Request made by you due to any or all of the causes and
									events mentioned in clause 8(1) above.{' '}
								</li>
								<li>
									You hereby expressly waive any claim against LEJA for losses
									incurred as a result of being scammed whether knowingly or
									unknowingly by allowing another person access to your device
									or data or as described in clause 8(2) above.
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							9. Termination
						</Box>
						<Box>
							<ol>
								<li>
									LEJA shall not be obliged to advance the Loan or, where the
									Loan has been advanced, may demand immediate payment of all
									amounts owed under this Agreement in the event that:
									<ol>
										<li>
											You fail to pay any sum payable hereunder on the Repayment
											Date.
										</li>
										<li>
											You fail to perform and/or observe any of your obligations
											under the Agreement.
										</li>
										<li>
											LEJA has reason to believe that you are unlikely to repay
											the facility
										</li>
									</ol>
								</li>
								<li>
									LEJA shall at any time, upon notice to you, terminate or vary
									its business relationship with you and cease to offer you the
									Services and in particular but without prejudice to the
									generality of the foregoing LEJA may cancel the Credit Limit
									which it has granted and require the repayment of outstanding
									debts resulting from such credits within such time as LEJA may
									determine.{' '}
								</li>
								<li>
									Without prejudice to LEJA’s rights under clause 10(a), LEJA
									may at its sole discretion suspend or close your access to the
									Services and the Leja Platform where:
									<ol>
										<li>
											If you use the Leja Platform for unauthorised purposes or
											where LEJA detects any abuse/misuse, breach of content,
											fraud or attempted fraud relating to your use of the
											Services;
										</li>
										<li>
											If your Mobile Money Account or agreement with a Mobile
											Network Operator is terminated for whatever reason;{' '}
										</li>
										<li>
											LEJA is required or requested to comply with an order or
											instruction of or a recommendation from the government,
											court, regulator or other competent authority;{' '}
										</li>
										<li>
											LEJA reasonably suspects or believes that you are in
											breach of these Terms and Conditions (including
											non-payment of any Loan amount due from you where
											applicable) which you fail to remedy (if remediable) after
											the service of notice by email, SMS or other electronic
											means requiring you to do so;{' '}
										</li>
										<li>
											If such a suspension, closure or termination is necessary
											as a consequence of technical problems or for reasons of
											safety; to facilitate update or upgrade the contents or
											functionality of the Services on the LEJA Platform from
											time to time;{' '}
										</li>
										<li>
											If you have, in the opinion of LEJA become inactive or
											dormant in the use of the Leja Platform or the Services;
										</li>
										<li>
											LEJA decides to suspend or cease the provision of the
											Services for commercial reasons or for any other reason as
											it may determine in its absolute discretion; or
										</li>
										<li>
											If LEJA receives notice of your demise. In this instance,
											any loan or dues owing shall be immediately offset against
											any money in the E-Wallet.
										</li>
									</ol>
								</li>
								<li>
									Without prejudice to the foregoing, termination shall not
									affect any accrued rights and liabilities of either party at
									the point of termination;
								</li>
								<li>
									In the event that LEJA receives notice of your demise, LEJA
									will not be obliged to allow any operation or access to the
									Services or accept Requests through your account by any person
									except upon production of administration letters from a
									competent court and/or confirmed grant of letters of
									administration or confirmed grant of Probate or Administration
									by your legal representatives duly appointed by a court of
									competent jurisdiction.
								</li>
								<li>
									Without prejudice to the foregoing, upon termination, you
									hereby consent to LEJA retaining Transactional records and
									your personal information for a minimum of Seven (7) years
									during which period the information may be used in the manner
									consented to on this agreement.
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							10. Indemnity
						</Box>
						<Box>
							<ol>
								<li>
									In consideration of LEJA complying with your instructions or
									Requests in relation to providing the Services, you undertake
									to indemnify LEJA and hold it harmless against any loss,
									charges, damages, expenses, fees or claims which LEJA suffers
									or incurs or sustains thereby and you absolve LEJA from all
									liability for loss or damage which you may sustain from LEJA
									acting on your instructions or requests through the LEJA
									Platform or in accordance with these Terms and Conditions.
								</li>
								<li>
									The indemnity in clause 10(1) shall also cover the following:
									<ol>
										<li>
											All demands, claims, actions, losses and damages of
											whatever nature which may be brought against LEJA or which
											it may suffer or incur arising from its acting or not
											acting on any Request or arising from the malfunction or
											failure or unavailability of any hardware, software, or
											equipment, the loss or destruction of any data, power
											failures, corruption of storage media, natural phenomena,
											riots, acts of vandalism, sabotage, terrorism, any other
											event beyond LEJA’s control, interruption or distortion of
											communication links or arising from reliance on any person
											or any incorrect, illegible, incomplete or inaccurate
											information or data contained in any Request received by
											LEJA.{' '}
										</li>
										<li>
											Any loss or damage that may arise from your use, misuse,
											abuse or possession of any third party software, including
											without limitation, any operating system, browser software
											or any other software packages or programs.{' '}
										</li>
										<li>
											Any unauthorised access to your Account or any breach of
											security or any destruction or accessing of your data or
											any destruction or theft of or damage to any of your
											Equipment.
										</li>
										<li>
											Any loss or damage occasioned by the failure by you to
											adhere to these Terms and Conditions and/or by supplying
											of incorrect information or loss or damage occasioned by
											the failure or unavailability of third party facilities or
											systems or the inability of a third party to process a
											transaction or any loss which may be incurred by LEJA as a
											consequence of any breach by these Terms and Conditions.
										</li>
										<li>
											Any damages and costs payable to LEJA in respect of any
											claims against LEJA for recompense for loss where the
											particular circumstance is within your control.{' '}
										</li>
									</ol>
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							11. Notices
						</Box>
						<Box>
							<ol>
								<li>
									LEJA shall send information concerning the Loan or Facility or
									Services via the APP and/or Short Messaging Service (SMS) to
									you through your Mobile Number, registered with a Mobile
									Network Operators, associated with your Loan or Facility or
									Service and registered on the Leja Platform.
								</li>
								<li>
									You may also reach to the Company via the Company’s email{' '}
									<a href='mailto: info@asilimia.co.ke'>
										support@asilimia.co.ke
									</a>{' '}
									or call center{' '}
									<a href='tel: +254 111 052280'> +254 111 052280</a>
								</li>
								<li>
									You acknowledge that you have no claim against LEJA for
									damages resulting from losses, delays, misunderstandings,
									mutilations, duplications or any other irregularities due to
									transmission of any communication pertaining to the Facility
									or Loan or Services.{' '}
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							12. Intellectual Property Rights
						</Box>
						<Box>
							<paragraph>
								You agree that LEJA may disclose details of the relating to your
								account to any Third party (including credit agencies) if in
								LEJA’s opinion such disclosure is necessary for the purpose of
								evaluating any application made to it or such Third Party or
								maintaining the operative account with LEJA or other purpose as
								the Company shall deem appropriate.
							</paragraph>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							13. Disclosure of information
						</Box>
						<Box>
							<paragraph>
								LEJA owns all intellectual Property rights relating to the Leja
								Platform and nothing in this agreement gives any right to any
								customer to the use or misuse of that intellectual property
								other than to access the services offered in the platform
								therein.
							</paragraph>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							14. Closure, Deletion and Recovery of Account
						</Box>
						<Box>
							<ol>
								<li>
									You may opt to Close and/or Delete their Account with LEJA
									when they no longer wish to access LEJA’s Services;
								</li>
								<li>
									An account can only be deleted and/or closed if there are no
									dues/loan owing and there is no money in the customer’s
									E-wallet. For clarity purposes an account cannot be deleted
									if:
									<ol>
										<li>
											There is any pending loan which you have not paid and/or
											cleared;
										</li>
										<li>There is any amount of money in your E-Wallet; and </li>
										<li>
											If there is any money in any account opened with a Lender
											through the LEJA Platform;
										</li>
									</ol>
								</li>
								<li>
									Where there is money in the E-Wallet or Account with the
									Lenders through LEJA, or non-withdrawable shares (where
									applicable), you may write to LEJA requesting to have the same
									Liquidated prior to the deletion of the Account.
								</li>
								<li>
									Where an account has been deleted successfully LEJA will
									retain the Application Data for Sixty (60) Days within which
									period if you decide to access LEJA’s services, you will not
									be required to register a new account. A new registration will
									only be required where the Sixty (60) Day period has lapsed
									after an account has been deleted.
								</li>
								<li>
									Without prejudice to the foregoing, you hereby consent to LEJA
									retaining Transactional records and your personal information
									for a minimum of Seven (7) years during which period the
									information may be used in the manner consented to on this
									agreement.
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							15. Dispute Resolution
						</Box>
						<Box>
							<ol>
								<li>
									Any dispute arising out of or relating to this Agreement, or
									the breach, termination or validity thereof (a Dispute) will
									be settled amicably between the parties.
								</li>
								<li>
									In the event that the dispute is not settled either Party has
									freedom to commence legal proceedings of any nature for the
									purposes of seeking legal redress.
								</li>
							</ol>
						</Box>
						<Box
							fontWeight='fontWeightBold'
							m={1}
							sx={heading}>
							16. Miscellaneous Provisions
						</Box>
						<Box>
							<ol>
								<li>
									No waiver by either party or any breach of any provision of
									this Agreement by either party shall be considered as a waiver
									of any subsequent breach of the same or any other provision.
								</li>
								<li>
									No failure on the part of any party to exercise, or delay on
									its part in exercising, any right, power or remedy provided by
									this Agreement or by law shall operate as a waiver thereof,
									nor shall any single or partial exercise of any such right,
									power or remedy preclude any further or other exercise of
									that, or any other, right, power or remedy.
								</li>
								<li>
									This Agreement constitutes the entire agreement between the
									parties in relation to its subject matter and supersedes all
									prior agreements and understandings whether oral or written
									with respect to such subject matter and no variation of this
									Agreement shall be effective unless reduced to writing and
									duly executed by or on behalf of each of the parties.
								</li>
								<li>
									The headings in the contract shall not affect its
									interpretation.
								</li>
								<li>
									Whenever required by context, the use of the singular number
									shall be constructed to include the plural and the use of the
									plural the singular and the use of any gender shall include
									both genders.
								</li>
								<li>
									If any terms or provision in the contract shall be held to be
									illegal or unenforceable, in whole or in part, under any
									enactment or rule of law. Such term or provision or part shall
									to that extent be deemed not to form part of this contract but
									the validity and enforceability of the remainder of the
									contract shall not be affected.
								</li>
								<li>
									This Agreement shall be governed by the laws of Kenya and the
									parties agree to submit disputes arising out of or about this
									Agreement to the courts in Kenya.
								</li>
							</ol>
						</Box>
					</Typography>
				</Grid>
			</Box>
			<Footer />
		</Container>
	);
};

export default OkoleaTermsAndConditions;
