import React, { useContext } from "react";
import "./navbar.scss";
import logo from "../../assets/asilimia-logo.webp";
import lejaplaystore from "./../../assets/google-play-badge.webp";
import TemporaryDrawer from "./menuBtn";
import { MouseContext } from "../../context/mouse-context";
import { Box, Link } from "@mui/material";

export const Navbar = () => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  return (
    <Box className="navigation">
      <Box
        className="logo-container"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <Link href="/">
          <img
            onMouseEnter={() => cursorChangeHandler("hovered")}
            onMouseLeave={() => cursorChangeHandler("")}
            src={logo}
            alt="logo"
          />
        </Link>
      </Box>
      <Box className="nav-links-container">
        <Link
          href="/about"
          className="links"
          onMouseEnter={() => cursorChangeHandler("hovered")}
          onMouseLeave={() => cursorChangeHandler("")}
          data-aos="fade-left"
          data-aos-duration="1400"
        >
          ABOUT US
        </Link>
        <Link
          href="/"
          className="links"
          onMouseEnter={() => cursorChangeHandler("hovered")}
          onMouseLeave={() => cursorChangeHandler("")}
          data-aos="fade-left"
          data-aos-duration="1600"
        >
          LEJA
        </Link>
        <a
          href="https://play.google.com/store/apps/details?id=com.okoleainternational.okoleamobile&hl=en&gl=US"
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => cursorChangeHandler("hovered")}
          onMouseLeave={() => cursorChangeHandler("")}
        >
          <img src={lejaplaystore} alt="playstore" />
        </a>
      </Box>
      <TemporaryDrawer className="nav-drawer" />
    </Box>
  );
};

export const TransparentNavbar = () => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  return (
    <Box className="transparent-navigation">
      <Box
        className="logo-container"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <Link href="/">
          <img
            onMouseEnter={() => cursorChangeHandler("hovered")}
            onMouseLeave={() => cursorChangeHandler("")}
            src={logo}
            alt="logo"
          />
        </Link>
      </Box>
      <Box className="nav-links-container">
        <Link
          href="/about"
          className="links"
          onMouseEnter={() => cursorChangeHandler("hovered")}
          onMouseLeave={() => cursorChangeHandler("")}
          data-aos="fade-left"
          data-aos-duration="1400"
        >
          ABOUT US
        </Link>
        <Link
          href="/"
          className="links"
          onMouseEnter={() => cursorChangeHandler("hovered")}
          onMouseLeave={() => cursorChangeHandler("")}
          data-aos="fade-left"
          data-aos-duration="1600"
        >
          LEJA
        </Link>
        <a
          href="https://play.google.com/store/apps/details?id=com.okoleainternational.okoleamobile&hl=en&gl=US"
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => cursorChangeHandler("hovered")}
          onMouseLeave={() => cursorChangeHandler("")}
        >
          <img src={lejaplaystore} alt="playstore" />
        </a>
      </Box>
      <TemporaryDrawer className="nav-drawer" />
    </Box>
  );
};
