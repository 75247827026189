import Cory from "../../assets/Cory.webp";
import Judy from "../../assets/Judy.webp";
import Sam from "../../assets/Sam.webp";

export const testimonies = [
  {
    image: Sam,
    name: "Sam",
    testimony: `"This is a perfect app for me. The transaction is definitely free, it saves time and, above all, it’s accurate."`,
  },
  {
    image: Judy,
    name: "Judy",
    testimony: `"Very nice app...Easy to understand and I can manage all the transactions I make. Very much recommended"`,
  },
  {
    image: Cory,
    name: "Cory",
    testimony: `"Leja app helps me save time and money, which I reinvest into my business."`,
  },
  {
    image: Sam,
    name: "Sam",
    testimony: `"This is a perfect app for me. The transaction is definitely free, it saves time and, above all, it’s accurate."`,
  },
  {
    image: Judy,
    name: "Judy",
    testimony: `"Very nice app...Easy to understand and I can manage all the transactions I make. Very much recommended."`,
  },
  {
    image: Cory,
    name: "Cory",
    testimony: `"Leja app helps me save time and money, which I reinvest into my business."`,
  },
];
