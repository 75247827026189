import profilePic from "./assets/profile.png";
import Aseta from "./assets/Aseta.png";
import Azadi from "./assets/Azadi.png";
import Becky from "./assets/Becky.png";
import Cate from "./assets/Cate.png";
import Eve from "./assets/Eve.png";
import George from "./assets/George.png";
import Gideon from "./assets/Gideon.png";
import Marvin from "./assets/Marvin.png";
import Mary from "./assets/Mary.png";
import Morgane from "./assets/Morgane.png";
import Morris from "./assets/Morris.png";
import Pat from "./assets/Pat.png";
import Phena from "./assets/Phena.png";
import Raphael from "./assets/Raphael.png";
import Tekwane from "./assets/Tekwane.png";
import Vincent from "./assets/Vincent.png";
import Wachira from "./assets/Wachira.png";
import Wambura from "./assets/Wambura.png";

export const team = [
  {
    image: Tekwane,
    name: "Tekwane",
    role: "Co-Founder & CEO",
  },
  {
    image: Morgane,
    name: "Morgane",
    role: "Co-Founder & COO",
  },
  {
    image: Raphael,
    name: "Raphael",
    role: "Accounts Manager",
  },
  {
    image: George,
    name: "George",
    role: "CTO",
  },
  {
    image: Phena,
    name: "Phena",
    role: "Android Developer",
  },
  {
    image: Pat,
    name: "Patsheba",
    role: "Full Stack Engineer",
  },
  {
    image: Wachira,
    name: "Wachira",
    role: "Back-End Engineer",
  },
  {
    image: Marvin,
    name: "Marvin",
    role: "Android Developer",
  },
  {
    image: Becky,
    name: "Rebecca",
    role: "Technical Product Manager",
  },
  {
    image: Mary,
    name: "Mary",
    role: "Sales Manager",
  },
  {
    image: Wambura,
    name: "Wambura",
    role: "Front-End Engineer",
  },
  {
    image: Azadi,
    name: "Azadi",
    role: "UI/UX Designer",
  },
  {
    image: Morris,
    name: "Morris",
    role: "Sales Manager",
  },
  {
    image: Cate,
    name: "Cate",
    role: "Customer service manager",
  },
  {
    image: Gideon,
    name: "Gideon",
    role: "Back-End Engineer",
  },
  {
    image: Vincent,
    name: "Vincent",
    role: "Quality Assurance Engineer",
  },
  {
    image: Aseta,
    name: "Aseta",
    role: "Quality Assurance Engineer",
  },
  {
    image: Eve,
    name: "Evelyn",
    role: "Marketing Officer",
  },
  {
    image: profilePic,
    name: "James",
    role: "Flutter Developer",
  },
];
