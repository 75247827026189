import { Link } from "@mui/material";

export const TermsAndConditionsLink = () => {
    return (
        <>
            {" "}
            <Link href="/leja/terms-and-conditions">
                Leja application terms and conditions
            </Link>
            {" "}
        </>
    );
};
