export const LejaAppPlaystoreLink = ({ children }) => {
    return (
        <>
            {" "}
            <a
                target="_blank"
                without 
                rel="noreferrer" 
                href="https://play.google.com/store/apps/details?id=com.okoleainternational.okoleamobile&hl=en_IN"
            >
                {children}
            </a>
            {" "}
        </>
    );
};
