import React, { useContext, useState } from "react";
import Slider from "react-slick";

// material UI
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Container } from "@mui/material";
import { Navbar } from "../../components/navigation/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import logo from "../../assets/leja.webp";
import bannerLine from "../../assets/Vector9.webp";
import Cory from "../../assets/Cory.webp";
import Judy from "../../assets/Judy.webp";
import Sam from "../../assets/Sam.webp";

import Payments from "../../assets/payments.webp";
import debtReminder from "../../assets/debt-reminders.webp";
import bookKeeping from "../../assets/book-keeping.webp";
import Loans from "../../assets/loans.webp";
import Hand from "../../assets/hand.webp";
import Phone1 from "../../assets/phone1.webp";
import Phone2 from "../../assets/phone2.webp";
import Phone3 from "../../assets/phone3.webp";
import Phone4 from "../../assets/phone4.webp";
import Phone5 from "../../assets/phone6.webp";

import Footer from "../../components/footer/footer";
import { testimonies } from "./testimonies";
import {
  StyledButton,
  StyledLink,
  StyledParagraph,
  StyledH2,
} from "../../components/utils/styledComponents";
import { MouseContext } from "../../context/mouse-context";

import "./leja.scss";
import { Typography } from "@mui/material";

const appLink =
  "https://play.google.com/store/apps/details?id=com.okoleainternational.okoleamobile&hl=en&gl=US";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(240, 237, 230, 0.6)",
  fontFamily: 'font-family: "Rubik", sans-serif',
  padding: theme.spacing(1),
  textAlign: "center",
  color: "black",
}));

const IntroSection = () => {
  return (
    <Grid
      container
      className="leja-banner"
      sx={{
        backgroundColor: "#f0ede6",
        position: "relative",
        pt: "5rem",
      }}
      justify="space-between"
      spacing={8}
    >
      <Grid
        container
        display={{ md: "none", xs: "flex" }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="banner-img__container-phone"
      >
        <Typography
          fontSize="1.5rem"
          className="title"
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          The simplest way to manage your business.
        </Typography>
        <Box
          className="banner-logo_container"
          data-aos="fade-up"
          data-aos-duration="3000"
          data-aos-delay="1000"
        >
          <img className="leja-logo" src={logo} alt="logo" />
        </Box>
        <Box
          className="get-app_container"
          data-aos="fade-up"
          data-aos-duration="3000"
          data-aos-delay="1000"
        >
          <button className="get-app__btn">
            <a className="link" target="_blank" rel="noreferrer" href={appLink}>
              Get the app
            </a>
          </button>
        </Box>
      </Grid>
      <Grid
        item
        md={7}
        sm={8}
        display={{ xs: "none", sm: "block" }}
        paddingTop={{ sm: "64px", xs: "0" }}
        className="left"
      >
        <Grid
          item
          className="banner-img_container"
          data-aos="fade-up"
          data-aos-duration="3000"
          flexDirection="column"
          justify="center"
          alignItems="center"
          display="flex"
          sx={{ pb: "3rem" }}
        >
          <img className="banner-line" src={bannerLine} alt="line" />
          <img className="leja-logo" src={logo} alt="logo" />
          <Box
            className="text-container"
            width="80%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography
              className="main-text"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-delay="1000"
              sx={{
                mt: "1.5rem",
                color: "#009966",
                textAlign: "left",
                lineHeight: 1,
              }}
              fontSize={{
                lg: "3.5rem",
                md: "2.5rem",
                xs: "2.1rem",
              }}
            >
              The simplest way to manage your business
            </Typography>
            <Typography
              className="sub-text"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-delay="2000"
              sx={{
                mt: "1.5rem",
                textAlign: "left",
                maxWidth: "80%",
                fontWeight: 300,
                color: "black",
              }}
              fontSize={{
                lg: "1.2rem",
                md: "1rem",
                xs: "0.9rem",
              }}
            >
              Whereas you may have had to use written records in the past, Leja
              now allows you to access and manage all your business finances
              anytime and anywhere.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        md={5}
        sm={4}
        display={{ xs: "none", sm: "block" }}
        className="right"
        data-aos="fade-left"
        data-aos-duration="3000"
        data-aos-delay="1000"
        position="relative"
      >
        <img src={Hand} alt="hand" />
      </Grid>
    </Grid>
  );
};

const WhyUseLejaSection = () => {
  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "white" }}
      className="why-container"
    >
      <Box
        className="why"
        data-aos="fade-down"
        data-aos-duration="2000"
        data-aos-delay="1500"
      >
        <Typography variant="body1" className="heading-phone">
          Leja is the simplest way to manage your business. Whereas you may have
          had to use written records in the past, Leja now allows you to access
          and manage all your business finances anytime and anywhere.
        </Typography>
        <Typography
          className="heading"
          data-aos="fade-down"
          data-aos-duration="2000"
          fontSize={{ sm: "3rem", xs: "1.5rem" }}
          fontWeight={{ sm: "700" }}
          sx={{ m: "2rem 0" }}
        >
          Why use Leja?
        </Typography>
      </Box>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: "19rem 0 9rem 0" }}
        className="reasons-container"
      >
        <Grid
          item
          sm={4}
          xs={10}
          className="reason"
          data-aos="fade-down"
          data-aos-duration="2200"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justfyContent="center"
        >
          <Box className="reason-title">
            <Typography
              variant="h2"
              fontSize={{ sm: "1.5rem", xs: "1.2rem" }}
              fontWeight="800"
              textTransform="uppercase"
              className="title"
            >
              Simple
            </Typography>
          </Box>
          <Box width={{ sm: "73%", sx: "100%" }} className="reason-description">
            <Typography
              textAlign="center"
              variant="body1"
              lineHeight="1.5"
              fontSize={{ sm: "1.2rem", xs: "1rem" }}
              className="description"
            >
              No need for accounting skills and work with any type of devices.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sm={4}
          xs={10}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justfyContent="center"
          className="reason"
          data-aos="fade-down"
          data-aos-duration="2600"
          data-aos-delay="2000"
        >
          <Box className="reason-title">
            <Typography
              variant="h2"
              fontSize={{ sm: "1.5rem", xs: "1.2rem" }}
              fontWeight="800"
              textTransform="uppercase"
              className="title"
            >
              Free
            </Typography>
          </Box>
          <Box width={{ sm: "73%", sx: "100%" }} className="reason-description">
            <Typography
              textAlign="center"
              variant="body1"
              lineHeight="1.5"
              fontSize={{ sm: "1.2rem", xs: "1rem" }}
              className="description"
            >
              You can record all your transactions and make B2B payments for
              free.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sm={4}
          xs={10}
          className="reason"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justfyContent="center"
          data-aos="fade-down"
          data-aos-duration="2900"
          data-aos-delay="3000"
        >
          <Box className="reason-title">
            <Typography
              variant="h2"
              fontSize={{ sm: "1.5rem", xs: "1.2rem" }}
              fontWeight="800"
              textTransform="uppercase"
              className="title"
            >
              Secure
            </Typography>
          </Box>
          <Box width={{ sm: "73%", sx: "100%" }} className="reason-description">
            <Typography
              textAlign="center"
              variant="body1"
              lineHeight="1.5"
              fontSize={{ sm: "1.2rem", xs: "1rem" }}
              className="description"
            >
              The more you use Leja, the more funding you can get.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
const TestimonialSection = ({ cursorChangeHandler }) => {
  const NextArrow = ({ onClick }) => (
    <div className="arrow next" onClick={onClick}>
      <ArrowForwardIcon
        onMouseEnter={() => cursorChangeHandler("hovered")}
        onMouseLeave={() => cursorChangeHandler("")}
      />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="arrow prev" onClick={onClick}>
      <ArrowBackIcon
        onMouseEnter={() => cursorChangeHandler("hovered")}
        onMouseLeave={() => cursorChangeHandler("")}
      />
    </div>
  );

  const [imgIndex, setImgIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImgIndex(next),
  };

  return (
    <Box
      className="testimonies-container"
      width="90%"
      padding="2rem 0 1rem 0"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        className="title-container"
        textAlign="center"
        data-aos="fade-up"
        data-aos-duration="2900"
      >
        <Typography
          fontSize="3rem"
          fontWeight="700"
          variant="h2"
          className="title"
        >
          Trusted by 50,000+ microbusinesses in Kenya
        </Typography>
      </Box>
      {/* carousel container */}
      <Carousel
        autoPlay="true"
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        className="phone-testimonies"
      >
        <Box className="testimony">
          <Box className="img-container">
            <img src={Sam} alt="human" />
          </Box>
          <Box className="testimony-text">
            <h2 className="name">Sam</h2>
            <p className="testimonial">
              "This is a perfect app for me. The transaction is definitely free,
              it saves time and, above all, it’s accurate."
            </p>
          </Box>
        </Box>
        <Box className="testimony">
          <Box className="img-container">
            <img src={Judy} alt="human" />
          </Box>
          <Box className="testimony-text">
            <h2 className="name">Judy</h2>
            <p className="testimonial">
              "Very nice app...Easy to understand and I can manage all the
              transactions I make. Very much recommended."
            </p>
          </Box>
        </Box>
        <Box className="testimony">
          <Box className="img-container">
            <img src={Cory} alt="human" />
          </Box>
          <Box className="testimony-text">
            <h2 className="name">Cory</h2>
            <p className="testimonial">
              "Leja app helps me save time and money, which I reinvest into my
              business."
            </p>
          </Box>
        </Box>
      </Carousel>
      {/* carousel container */}
      <div className="testimonies">
        <Slider {...settings}>
          {testimonies.map((testimony, index) => (
            <div
              className={index === imgIndex ? "testimony active" : "testimony"}
            >
              <div className="img-container">
                <img src={testimony.image} alt="person" />
              </div>
              <div className="text-container">
                <p className="name">{testimony.name}</p>
                <p className="testimonial">{testimony.testimony}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div
        className="youtube"
        data-aos="zoom-in"
        data-aos-duration="3000"
        data-aos-delay="2000"
      >
        <iframe
          src="https://www.youtube.com/embed/yllwU8dJuPI"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Box>
  );
};

const HowDoesItWorkSection = () => {
  const phones = [Phone1, Phone2, Phone3, Phone4, Phone5];
  return (
    <Container
      disableGutters
      maxWidth="fluid"
      sx={{
        paddingBottom: "10rem",
        paddingTop: "10rem",
      }}
    >
      <Box textAlign={"center"}>
        <Typography
          fontSize={{ xs: "3rem" }}
          lineHeight="1.2"
          fontWeight={{ lg: 700 }}
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          How does it work?
        </Typography>
      </Box>
      <Grid
        container
        columns={{ md: 15, xl: 12 }}
        justifyContent={"center"}
        data-aos="zoom-in"
        data-aos-duration="3000"
        data-aos-delay="1000"
        paddingY={"2rem"}
      >
        <Grid item sm={7} md={4} lg={2.5}>
          <Carousel
            showArrows={false}
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            transitionTime={0.01}
            showIndicators={false}
            stopOnHover={true}
            swipeable={false}
            autoPlay
            className="shadowed"
          >
            {phones &&
              phones.map((phone, index) => (
                <Box>
                  <img src={phone} alt={`phone${index}`} srcset={phone} />
                </Box>
              ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  );
};

const BookKeepingSection = ({ cursorChangeHandler }) => {
  return (
    <Container
      disableGutters={{ xs: true, xl: false }}
      sx={{ paddingBottom: "10rem", paddingTop: "10rem" }}
    >
      <Grid
        container
        justifyContent={{
          xs: "center",
          md: "space-evenly",
          xl: "space-between",
        }}
        alignItems={{ xs: "center" }}
        columns={{ md: 15, xl: 12 }}
        direction={{ xs: "column-reverse", md: "row" }}
        // paddingX={{ xs: '1rem' }}
      >
        <Grid item md={4} lg={3}>
          <Box
            data-aos="fade-right"
            data-aos-duration="3000"
            data-aos-delay="1000"
            className="shadowed"
          >
            <img
              style={{
                height: "auto",
                width: "100%",
              }}
              src={bookKeeping}
              alt="screenshot"
            />
          </Box>
        </Grid>
        <Grid item sm={4} lg={7} xl={6} paddingBottom={{ xs: "3rem", md: 0 }}>
          <Box
            data-aos="fade-left"
            data-aos-duration="3000"
            textAlign={{
              xs: "center",
              md: "left",
            }}
          >
            <StyledH2 variant="h2">Bookkeeping</StyledH2>
            <Box paddingY={{ xs: 2, sm: 4 }} justifyContent="center">
              <StyledParagraph variant="p">
                You can record your daily sales, expenses, and liabilities in
                our very simple digital bookkeeping.
              </StyledParagraph>
            </Box>
            <Box>
              <StyledButton
                variant="contained"
                onMouseEnter={() => cursorChangeHandler("hovered")}
                onMouseLeave={() => cursorChangeHandler("")}
              >
                <StyledLink
                  underline="none"
                  href={appLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  Get the app
                </StyledLink>
              </StyledButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const PaymentsSection = ({ cursorChangeHandler }) => {
  return (
    <Container
      disableGutters={{ xs: true, xl: false }}
      sx={{ paddingBottom: "10rem", paddingTop: "10rem" }}
    >
      <Grid
        container
        justifyContent={{
          xs: "center",
          md: "space-evenly",
          xl: "space-between",
        }}
        alignItems={{ md: "center" }}
        columns={{ md: 15, xl: 12 }}
        // paddingX={{ xs: '1rem' }}
      >
        <Grid item sm={4} lg={7} xl={6} paddingBottom={{ xs: "3rem", md: 0 }}>
          <Box
            data-aos="fade-left"
            data-aos-duration="3000"
            textAlign={{
              xs: "center",
              md: "left",
            }}
          >
            <StyledH2 variant="h2">Payments</StyledH2>
            <Box paddingY={{ xs: 2, sm: 4 }} justifyContent="center">
              <StyledParagraph variant="p">
                You can integrate your mobile money account to Leja and manage
                all your business payments in one place. You can pay your
                suppliers with no transaction fee and buy airtime from all telco
                providers.
              </StyledParagraph>
            </Box>
            <Box>
              <StyledButton
                variant="contained"
                onMouseEnter={() => cursorChangeHandler("hovered")}
                onMouseLeave={() => cursorChangeHandler("")}
              >
                <StyledLink
                  underline="none"
                  href={appLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  Get the app
                </StyledLink>
              </StyledButton>
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} lg={3}>
          <Box
            data-aos="fade-right"
            data-aos-duration="3000"
            data-aos-delay="1000"
            className="shadowed"
          >
            <img
              style={{
                height: "auto",
                width: "100%",
              }}
              src={Payments}
              alt="screenshot"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const DeptReminderSection = ({ cursorChangeHandler }) => {
  return (
    <Container
      disableGutters={{ xs: true, xl: false }}
      sx={{ paddingBottom: "10rem", paddingTop: "10rem" }}
    >
      <Grid
        container
        justifyContent={{
          xs: "center",
          md: "space-evenly",
          xl: "space-between",
        }}
        alignItems={{ xs: "center" }}
        columns={{ md: 15, xl: 12 }}
        direction={{ xs: "column-reverse", md: "row" }}
      >
        <Grid item md={4} lg={3}>
          <Box
            data-aos="fade-right"
            data-aos-duration="3000"
            data-aos-delay="1000"
            className="shadowed"
          >
            <img
              style={{
                height: "auto",
                width: "100%",
              }}
              src={debtReminder}
              alt="screenshot"
            />
          </Box>
        </Grid>
        <Grid item sm={4} lg={7} xl={6} paddingBottom={{ xs: "3rem", md: 0 }}>
          <Box
            data-aos="fade-left"
            data-aos-duration="3000"
            textAlign={{
              xs: "center",
              md: "left",
            }}
          >
            <StyledH2 variant="h2">Debt Reminders</StyledH2>
            <Box paddingY={{ xs: 2, sm: 4 }} justifyContent="center">
              <StyledParagraph variant="p">
                You can track all your pending payments and send reminders to
                your customers which owe you money.
              </StyledParagraph>
            </Box>
            <Box>
              <StyledButton
                variant="contained"
                onMouseEnter={() => cursorChangeHandler("hovered")}
                onMouseLeave={() => cursorChangeHandler("")}
              >
                <StyledLink
                  underline="none"
                  href={appLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  Get the app
                </StyledLink>
              </StyledButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const BusinessLoansSection = ({ cursorChangeHandler }) => {
  return (
    <Container
      disableGutters={{ xs: true, xl: false }}
      sx={{ paddingBottom: "10rem", paddingTop: "10rem" }}
    >
      <Grid
        container
        justifyContent={{
          xs: "center",
          md: "space-evenly",
          xl: "space-between",
        }}
        alignItems={{ md: "center" }}
        columns={{ md: 15, xl: 12 }}
      >
        <Grid item sm={4} lg={7} xl={6} paddingBottom={{ xs: "3rem", md: 0 }}>
          <Box
            data-aos="fade-left"
            data-aos-duration="3000"
            textAlign={{
              xs: "center",
              md: "left",
            }}
          >
            <StyledH2 variant="h2">Business Loans</StyledH2>
            <Box paddingY={{ xs: 2, sm: 4 }} justifyContent="center">
              <StyledParagraph variant="p">
                The more you use Leja, the more funding you can access to help
                you grow your business.
              </StyledParagraph>
            </Box>

            <Box>
              <StyledButton
                variant="contained"
                onMouseEnter={() => cursorChangeHandler("hovered")}
                onMouseLeave={() => cursorChangeHandler("")}
              >
                <StyledLink
                  underline="none"
                  href={appLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  Get the app
                </StyledLink>
              </StyledButton>
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} lg={3}>
          <Box
            className="shadowed"
            data-aos="fade-right"
            data-aos-duration="3000"
            data-aos-delay="1000"
          >
            <img
              style={{
                height: "auto",
                width: "100%",
              }}
              src={Loans}
              alt="screenshot"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const Lejapage = () => {
  // context api
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);

  return (
    <div className="lejapage">
      <Navbar />
      <IntroSection />
      <Box
        className="simplest-way_container"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContents="center"
        backgroundColor="rgba(240, 237, 230, 0.6)"
      >
        <WhyUseLejaSection />
        <TestimonialSection cursorChangeHandler={cursorChangeHandler} />
        <Box paddingY={{ xs: "2rem" }} backgroundColor="#fff">
          <HowDoesItWorkSection />
        </Box>
        <Box
          backgroundColor="#f0ede6"
          fontFamily='font-family: "Rubik", sans-serif'
        >
          <BookKeepingSection cursorChangeHandler={cursorChangeHandler} />
          <PaymentsSection cursorChangeHandler={cursorChangeHandler} />
          <DeptReminderSection cursorChangeHandler={cursorChangeHandler} />
          <BusinessLoansSection cursorChangeHandler={cursorChangeHandler} />
        </Box>
      </Box>
      <Container maxWidth={"fluid"} sx={{ bgcolor: "#fff" }}>
        <Footer />
      </Container>
    </div>
  );
};
export default Lejapage;
