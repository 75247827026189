import React, { useContext } from "react";
import { MouseContext } from "../../context/mouse-context";

import useMousePosition from "./mousePosition";
import "./cursor.scss";

const DotRing = () => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const { x, y } = useMousePosition(); // return x and y values from useMousePosition
  return (
    <>
      <div
        style={{ left: `${x}px`, top: `${y}px` }}
        className={"ring " + cursorType}
      ></div>
      {/* <div
        style={{ left: `${x}px`, top: `${y}px` }}
        className={"follow-dot" + cursorType}
      ></div> */}
    </>
  );
};

export default DotRing;
