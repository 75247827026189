import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Stack } from "@mui/material";

import { Navbar } from "../../components/navigation/navbar";
import Footer from "../../components/footer/footer";
import { TermsAndConditionsLink } from "../../components/faq/TermsAndConditionsLink";
import { LejaAppPlaystoreLink } from "../../components/faq/LejaAppPlaystoreLink";

const category = {
  justifyContent: "center",
  fontWeight: "bold",
  fontSize: "25px",
  marginTop: "1.5rem",
  marginBottom: "1rem",
};

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Navbar />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            marginTop: "6rem",
            fontWeight: "700",
            justifyContent: "center",
          }}
        >
          Frequently Asked Questions
        </Typography>
      </Stack>
      <Typography sx={category}>About Leja</Typography>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ color: "#009966" }}>What is Leja?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Leja app is a cash management and bookkeeping mobile application for
            small and micro businesses to take charge of their finances.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            In which countries does Leja operate?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>At the moment, Leja operates in Kenya.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Which networks does Leja support?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In Kenya, we support Safaricom. We hope to expand our coverage in
            the near future.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do I get the latest versions of the application?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              <li>
                You can follow this{" "}
                <LejaAppPlaystoreLink>link</LejaAppPlaystoreLink>
                and click Update. Or,
              </li>
              <li>Go to Google Play Store</li>
              <li>
                Search for <b>“Leja app”</b>
              </li>
              <li>
                Click <b>“Update”</b>
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography sx={category}>To get started with Leja</Typography>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do I create a Leja account?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            On your phone:
            <ol>
              <li>Open the Leja application</li>
              <li>
                Click “Next” to go through the first three introductory steps
              </li>
              Enter your phone number in the format “712 345 678”
              <li>
                Wait for your phone to automatically detect a One-Time-PIN
              </li>
              <li>Enter your first and last name</li>
              <li>Enter your email address</li>
              <li>Create a 4-digit PIN to secure your account</li>
              <li>Confirm the PIN by re-entering the same PIN</li>
              <li>Use the PIN created to transact on Leja</li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            I received a <b>“This number already has a Leja account”</b>{" "}
            message. What does that mean?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            his means you have used Leja or Okolea before, and you already have
            an account with all your transactions. Click <b>“Log in”</b> to
            access your account.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            I received an <b>“Incomplete number, must be 9 digits”</b> or{" "}
            <b>“Wrong characters, use number 0-9 only”</b> message.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This means you entered the phone number in the wrong format:
            <ol>
              <li>
                Make sure it is 9 digits as in <b>“712 345 678”</b> if your full
                number is 0712 345 678. What does that mean?
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Typography sx={category}>Transacting on Leja</Typography>
      <ol>
        <li>Send Money</li>
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              Why would I send money using Leja?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>You can send money to other MPesa users.</li>
                <li>You can send money to multiple MPesa users.</li>
                <li>You can send money at a reduced transaction cost.</li>
                <li>
                  You can view a summary of all money sent from/to specific
                  people.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How to send money using Leja.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>Click the “Send Money” button on the Home page.</li>
                <li>Enter the amount to send</li>
                <li>
                  Select a contact from your phonebook, or enter a phone number
                </li>
                <li>Click “Continue”</li>
                <li>Click “Confirm”</li>
                <li>Enter your MPesa pin to complete your transaction.</li>
                <b>You should receive two confirmation messages;</b>
                <li>From MPesa stating that you have sent money to Leja</li>
                <li>
                  From Leja stating your recipient's phone number and name with
                  the time and amount sent.
                </li>
              </ol>
              <b>
                After the transaction is complete, you will view the receipt for
                the transaction.
              </b>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              What might cause my send money to fail?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>While entering the recipient’s phone number;</b>
              <ol>
                <li>
                  Confirm the number is 10 digits long e.g. 0712 345 678 or 13
                  digits e.g. +254 712 345 678
                </li>
                <li>
                  Confirm that the phone number and amount are in the correct
                  format
                </li>
                <li>
                  Confirm there are only numbers in the phone number i.e. no
                  letters or special characters e.g. a-z or .,/;’[]()_+*%%#@!
                </li>
              </ol>

              <b>When entering the amount to send;</b>
              <ol>
                <li>
                  Confirm there are only numbers i.e. no letter or special
                  characters e.g. a-z or .,/;’[]()_+*%%#@!
                </li>
                <li>Confirm the amount is between 50-100,000 shillings</li>
              </ol>
              <ol>
                <li>
                  Ensure you have sufficient balance in your MPesa account for
                  the transaction, including the cost of the transaction.
                </li>
                <li>Ensure you have a stable internet connection</li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How to send money to many people on Leja?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>Click the “Send Money” button on the Home page</li>
                <li>Click “Send money to many”</li>
                <li>
                  Enter the amount you would like to send to that recipient
                </li>
                <li>
                  Click <b>“Add”</b>
                </li>
                <li>Add as many recipients as you would like to the list</li>
                <li>
                  If you would like to send the same amount to all recipients,
                  check the box next to <b>“Same amount for every recipient”</b>
                  .
                </li>
                <li>
                  Click <b>“Continue”</b>
                </li>
                <li>
                  Click <b>“Confirm”</b> after verifying that the amounts and
                  recipients are correct.
                </li>
                <li>Enter your MPesa pin to complete your transaction.</li>
              </ol>
              <span>
                You can view the receipts for each transaction by clicking on
                the individual transactions in the list provided.
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <li sx={{ marginTop: "2rem" }}>Buying airtime</li>
        <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              What are the benefits of buying airtime using Leja?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>
                  You can buy airtime for your Safaricom, Airtel, Telkom and
                  Equitel sim cards.
                </li>
                <li>
                  You can buy airtime for another number on the same networks.
                </li>
                <li>
                  You can view a summary of all airtime bought from/to specific
                  people.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel13"}
          onChange={handleChange("panel13")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How can I buy airtime using Leja?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>
                  Click the <b>“Buy Airtime”</b> button on the Home screen
                </li>
                <li>
                  Choose <b>“Person to buy airtime” </b>for
                </li>
                <li>
                  Enter <b>“Airtime Amount”</b>
                </li>
                <li>
                  Choose a <b>“Network Provider”</b>.
                </li>
                <li>
                  Choose <b>“How to pay for airtime”</b>.
                </li>
                <li>
                  Click <b>“Continue”</b> after verifying that the recipients,
                  amount, network provider, and mode of payment are correct
                </li>
                <li>
                  Click <b>“Confirm”</b> after verifying that the recipients,
                  amount, network provider, and mode of payment are correct
                </li>
                <li>Enter your MPesa pin to complete your transaction.</li>
              </ol>
              <span>You should receive three confirmation messages;</span>
              <ol>
                <li>
                  From MPesa stating that you have paid the amount to Leja
                </li>
                <li>From Leja stating you have received airtime from Leja</li>
                <li>From ETOPUP confirming that you have received airtime</li>
              </ol>
              <span>
                After the transaction is complete, you will view the receipt for
                the transaction.
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel14"}
          onChange={handleChange("panel14")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              What might cause my buy airtime to fail?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>While entering the recipient’s phone number;</b>
              <ol>
                <li>
                  Confirm the number is 10 digits long e.g. 0712 345 678 or 13
                  digits e.g. +254 712 345 678
                </li>
                <li>
                  Confirm that the phone number and amount are in the correct
                  format
                </li>
                <li>
                  Confirm there are only numbers in the phone number i.e. no
                  letters or special characters e.g. a-z or .,/;’[]()_+*%%#@!
                </li>
              </ol>

              <b>When entering the amount to send;</b>
              <ol>
                <li>
                  Confirm there are only numbers i.e. no letter or special
                  characters e.g. a-z or .,/;’[]()_+*%%#@!
                </li>
                <li>Confirm the amount is between 20-8,000 shillings</li>
              </ol>
              <ol>
                <li>
                  Ensure you have sufficient balance in your MPesa account for
                  the transaction, including the cost of the transaction.
                </li>
                <li>Ensure you have a stable internet connection</li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <li>Pay bills/Buy Goods and Services.</li>
        <Accordion
          expanded={expanded === "panel15"}
          onChange={handleChange("panel15")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How to pay bills using Leja.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>
                  Click the <b>“Pay”</b> button on the Home screen
                </li>
                <li>
                  Select <b>“Pay Bill” </b>
                </li>
                <li>
                  Enter the Business Number you would like to pay to or select
                  from the Popular Bills list.
                </li>
                <li>Enter the Account Number.</li>
                <li>Enter the amount to pay</li>
                <li>
                  Select a contact from your phonebook, or enter a phone number.
                </li>
                <li>
                  Click <b>"Pay bill"</b>
                </li>
                <li>
                  Click <b>"Confirm"</b>
                </li>
                <li>Enter your MPesa pin to complete your transaction.</li>
              </ol>
              <span>You should receive two confirmation messages;</span>
              <ol>
                <li>
                  From MPesa stating that you have paid money to the pay bill of
                  your choice
                </li>
                <li>
                  From the pay bill stating the amount and time of payment.
                </li>
              </ol>
              <span>
                After the transaction is complete, you will view the receipt for
                the transaction.
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel15"}
          onChange={handleChange("panel15")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How to buy goods and services using Leja.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>
                  Click the <b>“Pay”</b> button on the Home screen
                </li>
                <li>
                  Select <b>“buy goods and services ” </b>
                </li>
                <li>Enter the Till Number you would like to pay to.</li>
                <li>Enter the amount to pay</li>
                <li>
                  Select a contact from your phonebook, or enter a phone number.
                </li>
                <li>
                  Click <b>"buy goods"</b>
                </li>
                <li>
                  Click <b>"Confirm"</b>
                </li>
                <li>Enter your MPesa pin to complete your transaction.</li>
              </ol>
              <span>You should receive two confirmation messages;</span>
              <ol>
                <li>
                  From MPesa stating that you have paid money to the till number
                  of your choice
                </li>
                <li>
                  From the pay bill stating the amount and time of payment.
                </li>
              </ol>
              <span>
                After the transaction is complete, you will view the receipt for
                the transaction.
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <li>Book Keeping</li>
        <Accordion
          expanded={expanded === "panel16"}
          onChange={handleChange("panel16")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How to record transactions using Leja.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>
                  Click the <b>“record”</b> Button.
                </li>
                <li>
                  Click <b>”record first transaction, or record”</b> to record
                  your transaction eg cash transaction.
                </li>
                <li>Enter the amount.</li>
                <li>
                  Select payment type <b>“Expense or income”</b>.
                </li>
                <li>Select payment method Cash, Mpesa or Credit.</li>
                <li>Input customer name and phone number.</li>
                <li>Input type of stock or service offered.</li>
                <li>Enter the date of the transaction.</li>
                <li>You should receive two confirmation messages;</li>
                <li>
                  Select yes or no depending on whether the transaction was
                  paid.
                </li>
                <li>Take a picture of the receipt</li>
                <li>
                  Lastly, tap to continue to complete the recording transaction.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel17"}
          onChange={handleChange("panel17")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              What might cause my record transaction to fail?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>When entering the amount to send;</b>
              <ol>
                <li>
                  Confirm there are only numbers i.e. no letter or special
                  characters e.g. a-z or .,/;’[]()_+*%%#@!
                </li>
                <li>Confirm the amount is between 1-1,000,000 shillings</li>
                <li>You must enter an amount.</li>
              </ol>
              <b>When entering the customer name</b>
              <ol>
                <li>
                  Confirm there are only letters (A-Z) i.e. no special
                  characters e.g. a-z or .,/;’[]()_+*%%#@!
                </li>
                <li>You must input one name</li>
              </ol>
              <b>While entering the recipient’s phone number;</b>
              <ol>
                <li>
                  Confirm the number is 10 digits long e.g. 0712 345 678 or 13
                  digits e.g. +254 712 345 678
                </li>
                <li>
                  Confirm that the phone number and amount are in the correct
                  format
                </li>
                <li>
                  Confirm there are only numbers in the phone number i.e. no
                  letters or special characters e.g. a-z or .,/;’[]()_+*%%#@!
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <li sx={category}> Loans</li>

        <Accordion
          expanded={expanded === "panel18"}
          onChange={handleChange("panel18")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How do I get an Airtime loan from the Leja app?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Leja provides users with access to Airtime loans that are of low
              interest rates; (amount is deposited directly to the user's
              registered MPESA number).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel19"}
          onChange={handleChange("panel19")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              What does being an active user mean?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              For you to get a loan in Leja you need to be an active user for
              more than three months.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel20"}
          onChange={handleChange("panel20")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How to apply for Leja airtime Loan
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol>
                <li>
                  Click the <b>“Loan”</b> button on the Home page.
                </li>
                <li>
                  Select <b>"Get a Loan"</b>
                </li>
                <li>Enable camera,location and read sms</li>
                <li>
                  Complete the KYC process by inputting:
                  <ul>
                    <li> Profile picture</li>
                    <li>Date of Birth</li>
                    <li>Employment Status</li>
                    <li>ID Number</li>
                    <li>Allow CRB consent </li>
                  </ul>
                </li>
                <li>A loan limit offer available for you will be displayed.</li>
                <li>Tap apply loan</li>
                <li>Enter the loan amount you want to borrow.</li>
                <li>Submit loan request</li>
                <li>You should receive two confirmation messages</li>
                <ul>
                  <li>
                    CHANGE AGENTS - a success notification with the disbursed
                    amount, full repayment amount and the due date.
                  </li>
                  <li>SAFARICOM - confirming the recharged balance.</li>
                  <li>ETOPUP -Amount received.</li>
                </ul>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel21"}
          onChange={handleChange("panel21")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              I heard about loans in the LEJA app. How can I get one?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              For you to get a loan in Leja you need to be an active user for
              more than three months.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </ol>

      <Typography sx={category}>Refer and Earn</Typography>
      <Accordion
        expanded={expanded === "panel22"}
        onChange={handleChange("panel22")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How to share your referral code with others
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              <li>Copy code -Code copied to clipboard</li>
              <li>
                Share code via SMS, Whatsapp and Facebook -Open platform with
                preloaded message
              </li>
              <li>Copy link- Code copied to clipboard</li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel23"}
        onChange={handleChange("panel23")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do I refer my friends and other people to use Leja
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              <li>
                Share your referral code with a friend (either from within the
                app or directly)
                <ol>
                  <li>Copy code -Code copied to clipboard</li>
                  <li>
                    Share code via SMS, Whatsapp and Facebook -Open platform
                    with preloaded message
                  </li>
                  <li>Copy link- Code copied to clipboard</li>
                </ol>
              </li>
              <li>
                Ensure that your friend uses the referral code as is. If it is
                123TRF, your friend should input the code 123TRF NOT 123trf or
                123tRf.
              </li>
              <li>
                Your friend then has to proceed to Register AND Verify their
                phone number for you to earn referral amount.
              </li>
              <li>
                The referral bonus will be sent to you in terms of airtime to
                both you and the person referred.
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography sx={category}>Support</Typography>
      <Accordion
        expanded={expanded === "panel24"}
        onChange={handleChange("panel24")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How to use Leja customer support
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              <li>
                Select the feature you want support from eg. Transactions,
                record, refer and earn or loans
              </li>
              <li>
                Select a specific issue you are facing:
                <ul>
                  <li>Add a description to your question</li>
                  <li>Add a picture of the issue/concern</li>
                  <li>
                    Click <b>Submit</b>
                  </li>
                </ul>
              </li>
              <li>
                Click the <b>FAQ</b> link to find Frequently asked questions
              </li>
              <li>
                Call us directly via Leja customer care number{" "}
                <a variant="body2" href="tel: +254111052280">
                  +254 111 052280
                </a>
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography sx={category}>USSD</Typography>
      <Accordion
        expanded={expanded === "panel25"}
        onChange={handleChange("panel25")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Does Leja have a USSD?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Yes, dial *429#</Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            How do I verify my phone via USSD
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              <li>
                Ensure that the sim card that has been registered with Leja is
                in the current phone.
              </li>
              <li>Click PROCEED to verify your account</li>
              <li>
                A code will be displayed on your phone (*483*051*15*30*45#)
              </li>
              <li>Dial that code</li>
              <li>
                Once your number has been verified successfully, return here to
                complete verification.
              </li>
              <b>NB </b> DO NOT expect any verification code through this
              process, once you return to the app (step 5), you will be
              automatically be logged in.
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion> */}

      <Typography sx={category}>Error</Typography>
      <Accordion
        expanded={expanded === "panel26"}
        onChange={handleChange("panel26")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            Cannot download/update LEJA from Play Store
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              <li>
                Clear Play Store cache from your phone settings. Find this under
                Phone applications:
                <ul>
                  <li>Play store</li>
                  <li>Storage</li>
                  <li>Clear cache</li>
                </ul>
              </li>
              <li>
                Check that your phone does not require a system/software update
                that has been pending.
              </li>
              <li>Check that your internet connection is stable</li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel27"}
        onChange={handleChange("panel27")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            App always shows the UPDATE dialog when the UPDATE button is not
            visible on Play Store (I can only see OPEN)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This is an issue that arises when a user has installed their current
            version from other sources that are not Play Store e.g Xender, Flash
            Share etc. To solve this:
            <ol>
              <li>Uninstall current version of the application</li>
              <li>Head to Play store</li>
              <li>Select Manage your google account </li>
              <li>Install the app afresh</li>
            </ol>
            <b>NB:</b> Before performing any of the suggested solutions, confirm
            that the current version installed on the app is one directly
            downloaded from Play Store, otherwise, proceed to solutions
            provided.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel28"}
        onChange={handleChange("panel28")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ color: "#009966" }}>
            I have found an issue with the application and/or have a question
            that I couldn’t find an answer in this document. Who should I
            contact?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To get further assistance please contact support@asilimia.co.ke or
            you can call us +254 111 052280
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel29"}
        onChange={handleChange("panel29")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", color: "#009966", flexShrink: 0 }}>
            Unfortunately, Leja has stopped | LEJA keeps stopping
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              <li>
                Check that your device has sufficient storage, clear cache if
                necessary
              </li>
              <li>Could not update transactions on landing page</li>
              <li>
                Check that your internet connection is stable and click on ‘Try
                again’
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel30"}
        onChange={handleChange("panel30")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", color: "#009966", flexShrink: 0 }}>
            Operation cancelled
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This may be due to 2 issues
            <ol>
              <li>User need to update Mpesa menu</li>
              <li>User need to update sim card</li>
            </ol>
            To update Mpesa menu:
            <ul>
              <li>Go to Mpesa menu</li>
              <li>Go to my account</li>
              <li>Click update customer menu</li>
              <li>Enter your MPesa pin</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div id="leja-account-deletion" style={{ height: "28px" }}></div>
      <Typography sx={category}>Leja Account Deletion and Recovery</Typography>
      <ol>
        <li style={{ marginBottom: "0.75rem" }}>Leja Account Deletion</li>
        <Accordion
          expanded={expanded === "panel31"}
          onChange={handleChange("panel31")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              When Can I delete my account with Leja?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You may opt to Close and/or Delete your Account with LEJA when you no longer wish to access 
              LEJA's Services
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel32"}
          onChange={handleChange("panel32")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              What conditions can prevent my Leja app account not to be deleted?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              An account can only be deleted and/or closed if there are no dues/loan owing and there is no money in the customer's E-wallet. 
              For clarity purposes an account cannot be deleted if:
              <ol type="a">
                <li>
                  There is any pending loan which you have not paid and/or cleared;
                </li>
                <li>
                  There is any amount of money in your E-Wallet; and
                </li>
                <li>
                  If there is any money in any account opened with a Lender through the LEJA Platform; <br />
                  <b>NB:</b> Where there is money in the E-Wallet or Account with the Lenders through LEJA, 
                  or non-withdrawable shares (where applicable), you may write to LEJA requesting to have the same L
                  iquidated prior to the deletion of the Account.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel33"}
          onChange={handleChange("panel33")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              Where can I request for my Leja application account deletion?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Leja application account deletion can be initiated from the 
              <LejaAppPlaystoreLink>Leja application</LejaAppPlaystoreLink>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel34"}
          onChange={handleChange("panel34")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How can I delete my Leja application account?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ol type="a">
                <li>
                  Login to your 
                  <LejaAppPlaystoreLink>leja application</LejaAppPlaystoreLink>
                  using your account credentials.
                </li>
                <li>
                  Navigate to the side menu drawer
                </li>
                <li>
                  Click settings
                </li>
                <li>
                  Select “Delete Account”
                </li>
                <li>
                  Read “Terms and conditions for Leja account deletion”
                </li>
                <li>
                  Click “PROCEED” to continue with the deletion or Call support 
                  <a style={{ margin: "0 4px" }} href="tel:+254 111 052280">+254 111 052280</a> 
                  if you need assistance
                </li>
                <li>
                  Enter your Leja pin
                </li>
                <li>
                  Enter/upload Reason for requesting Leja application account deletion.
                </li>
                <li>
                  Confirm your request by manually typing “Delete my account”
                </li>
                <li>
                  Click “Confirm Delete” to finalize the process 
                </li>
                <li>
                  Your account is successfully scheduled for deletion in 60 days. You may receive periodic SMS rem 
                  regarding the state of your account within this period.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel35"}
          onChange={handleChange("panel35")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              What user data does Leja retain after an account is deleted?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul>
                <li>
                  Where an account has been deleted successfully LEJA will retain the Application Data for Sixty (60) Days within which period if you decide to access LEJA's services, 
                  you will not be required to register a new account. A new registration will only be required where the Sixty (60) Day period has lapsed after an account has been deleted.
                </li>
                <li>
                  LEJA retains Transactional records and your personal information for a minimum of Seven (7) years during which period the information may be used in the manner consented 
                  to during account opening in our <TermsAndConditionsLink />
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <li style={{ marginTop: "2rem", marginBottom: "0.75rem" }}>Leja Account Recovery</li>
        <Accordion
          expanded={expanded === "panel36"}
          onChange={handleChange("panel36")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              Can I recover my deleted Leja application account details?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes. Your account together with your application data can be retrieved within 60 days by following the steps below;
              <ol type="a">
                <li>
                  Download 
                  <LejaAppPlaystoreLink>Leja application</LejaAppPlaystoreLink>
                  on Google playstore
                </li>
                <li>
                  Login using your phone number and pin
                </li>
                <li>
                  You will receive a dialogue that states your account is under pending deletion request.
                </li>
                <li>
                  Close the dialogue
                </li>
                <li>
                  Click on “RECOVER ACCOUNT”
                </li>
                <li>
                  Enter your registered email address
                </li>
                <li>
                  Verification email is sent to your email address
                </li>
                <li>
                  Click on the link and you will be redirected to the Leja app
                </li>
                <li>
                  Enter your ID number and click “Continue”
                </li>
                <li>
                  Create a new Leja pin 
                </li>
                <li>
                  Confirm pin
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel37"}
          onChange={handleChange("panel37")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              After how long can my Leja application account be available after deletion?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              60 days. 
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel38"}
          onChange={handleChange("panel38")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How many reminders do I receive before my account is permanently deleted?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You may receive periodic SMS reminders regarding the state of your account deletion request within 60 days.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel39"}
          onChange={handleChange("panel39")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              How does Leja utilize the personal data and transactional data retained after?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Leja will only utilize your personal data as stipulated in Clause 4 of the <TermsAndConditionsLink />
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel40"}
          onChange={handleChange("panel40")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ color: "#009966" }}>
              Can I recover my account details after the deletion request period has expired?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No. For you to access Leja services, you will be required to create a new account.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </ol>

      <Footer />
    </Container>
  );
}
